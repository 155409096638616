import React, { useMemo } from 'react'
import { Grid, Tab, Tabs, Typography, makeStyles } from '@material-ui/core'
import _ from 'lodash'
import Members from './Members'
import Labels from './Labels'
import StickyEscalation from './StickyEscalation'
import DashboardConfig from './DashboardConfig'
import ActivityTypes from './ActivityTypes'
import About from './About'
import StickyGroup from './StickyGroup'
import TabPanel from 'components/TabPanel'
import { useUrlSearchParams } from 'hooks/use-url-search-params'
import PropTypes from 'prop-types'
import { isModuleHasPerm } from 'utils'
import { usePermission } from 'hooks/usePermission'
import { PERMISSION_TYPE, ROLE_MODULES } from 'constants/modules'

SettingsTabs.propTypes = {
	isOwner: PropTypes.bool.isRequired,
	glassCode: PropTypes.string.isRequired,
	openAddMemDialog: PropTypes.func.isRequired,
}

const defaultTabId = 'about'

const useStyles = makeStyles((theme) => ({
	tabs: {
		minHeight: 'unset',
		borderBottom: `1px solid ${theme.palette.divider}`,
	},
	tab: {
		minHeight: 'unset',
		textTransform: 'unset',
	},
}))

function SettingsTabs({ isOwner, glassCode, dashboard, openAddMemDialog, onClickDeleteBtn, onClickEditBtn }) {
	const classes = useStyles()

	const glassPerms = usePermission(ROLE_MODULES.GLASS)
	const isAccessible = isOwner && isModuleHasPerm(glassPerms, PERMISSION_TYPE.CREATE)

	const settingsTabs = [
		{
			id: 'about',
			label: 'About',
			tabPanel: About,
		},
		{
			id: 'members',
			label: 'Members',
			tabPanel: Members,
		},
		{
			id: 'labels',
			label: 'Labels',
			tabPanel: Labels,
		},
		{
			id: 'activity-types',
			label: 'Activity Types',
			tabPanel: ActivityTypes,
		},
		{
			id: 'sticky-esc',
			label: 'Sticky Escalation',
			tabPanel: StickyEscalation,
		},
		isAccessible && {
			id: 'dashbaord-config',
			label: 'Dashboard Config',
			tabPanel: DashboardConfig,
		},
		{
			id: 'sticky-group',
			label: 'Sticky Group',
			tabPanel: StickyGroup,
		},
	].filter(Boolean)

	const [pageQuery, setPageQuery] = useUrlSearchParams({}, { tabId: String })
	const activeTabIdx = useMemo(() => _.findIndex(settingsTabs, { id: _.get(pageQuery, 'tabId') || defaultTabId }), [pageQuery])

	const onChangeTab = (e, tabIdx) => {
		setPageQuery({ tabId: _.get(settingsTabs, `[${tabIdx}].id`) }, true)
	}

	return (
		<Grid container>
			<Grid item xs={12}>
				<Tabs
					variant='scrollable'
					className={classes.tabs}
					size='small'
					value={activeTabIdx}
					indicatorColor='primary'
					textColor='primary'
					onChange={onChangeTab}
					aria-label='disabled tabs example'
				>
					{_.map(settingsTabs, (tab, index) => (
						<Tab key={tab.id} className={classes.tab} label={<Typography variant='body2'>{tab.label}</Typography>} />
					))}
				</Tabs>
			</Grid>
			<Grid item xs={12}>
				{_.map(settingsTabs, (tab, index) => (
					<TabPanel key={tab.id} label={tab.label} value={activeTabIdx} index={index}>
						{React.createElement(tab.tabPanel, { isOwner, glassCode, dashboard, openAddMemDialog, onClickEditBtn, onClickDeleteBtn })}
					</TabPanel>
				))}
			</Grid>
		</Grid>
	)
}

export default SettingsTabs
