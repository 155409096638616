import React, { useEffect, useState } from 'react'
import Board from 'react-trello'
import { makeStyles, useTheme, useMediaQuery } from '@material-ui/core'
import CardSec from './Card'
import NewLaneSection from './NewLaneSection'
import LaneHeader from './LaneHeader'
import NewLaneForm from './NewLaneForm'
import NewCardForm from './NewCardForm'
import AddCardLink from './AddCardLink'
import { useDispatch, useSelector } from 'react-redux'
import { convertLocalTimeToGmtStr, getErrMsg, getSuccessMsg } from 'utils'
import _ from 'lodash'
import {
	addGlassPane,
	createSticky,
	moveSticky,
	removeGlassPane,
	updateGlassPane,
	updateGlassPanePosition,
	updateStickyData,
} from 'services/glass/actions'
import { showSnackbarWithTimeout } from 'services/snackbar/actions'
// import LoadingBoard from './LoadingBoard'
import { hideLoader, showConfirmMessage, showLoader } from 'services/loader/actions'
import { useMemo } from 'react'
// import { eventBus, setEventBus } from './event-bus'
import AppEventBus from 'utils/event-bus'
import { STICKY_ESC_TIMER } from './constants'
import { BoardContextProvider } from './BoardContext'
import LoadingIndicator from 'components/LoadingIndicator'

const DEFAULT_STICKY_POS = 65535

const useStyles = makeStyles((theme) => ({
	root: {
		overflowX: 'auto',
		'& .react-trello-board': {
			height: `calc(100vh - (48px + 74px))`,
			scrollbarWidth: 'thin',
		},
		'& .react-trello-lane': {
			maxHeight: `calc(100vh - (50px + 74px))`,
			maxWidth: 284,
			'& > div': {
				scrollbarWidth: 'thin',
			},
		},
		'& ::-webkit-scrollbar, ::-webkit-scrollbar-track': {
			width: '4px',
			height: '8px',
			'-webkit-appearance': 'none',
			backgroundColor: 'rgba(255, 255, 255, 0)', //, //'transparent'
		},
		'& ::-webkit-scrollbar-thumb': {
			backgroundColor: 'rgba(213, 213, 220, 1)',
			height: '80px',
			borderRadius: '5px',
		},
	},
	AddLaneBtn: {
		backgroundColor: '#fff',
	},
}))

const KanabanBoard = ({
	isFetching,
	data,
	glassCode,
	sessionId,
	glassMembers,
	isCreateAllowed,
	isEditAllowed,
	isDeleteAllowed,
	isOwner,
	isGuest,
	history,
}) => {
	const classes = useStyles()
	const theme = useTheme()
	const [addPaneLoading, setAddPaneLoading] = useState(false)
	const [paneUpdateData, setPaneUpdateData] = useState({
		loading: false,
		paneId: '',
	})
	const isMobileDevice = useMediaQuery(theme.breakpoints.down('xs'))
	const { stickyEscValHrs, stickyRemindMins } = useSelector((state) => ({
		stickyEscValHrs: _.get(state, 'glass.settings.sticky_escalation'),
		stickyRemindMins: _.get(state, 'glass.settings.sticky_reminder'),
	}))
	const glassLabels = useSelector((state) => _.get(state, 'glass.settings.labels'))
	const glassStickyGroup = useSelector((state) => _.get(state, 'glass.settings.sticky_group'))
	const glassLayout = useSelector((state) => _.get(state, 'glass.settings.layout'))
	const token = useSelector((state) => state?.session?.authToken)

	const dispatch = useDispatch()
	const components = {
		Card: CardSec,
		NewLaneSection,
		LaneHeader: LaneHeader,
		NewLaneForm: NewLaneForm,
		NewCardForm: NewCardForm,
		AddCardLink,
	}

	const onClickAddLane = (title, callBack) => {
		setAddPaneLoading(true)
		const onSuccess = (res) => {
			dispatch(showSnackbarWithTimeout(getSuccessMsg(res), 1500))
			setAddPaneLoading(false)
			callBack()
		}
		const onFailure = (err) => {
			dispatch(showSnackbarWithTimeout(getErrMsg(err), 1500))
			setAddPaneLoading(false)
			callBack()
		}
		dispatch(addGlassPane(sessionId, glassCode, title, onSuccess, onFailure))
	}

	const onClickPaneUpdate = (paneId, paneName, panePosition, prevPanePosition, isEdit) => {
		setPaneUpdateData((prevState) => ({ ...prevState, loading: true, paneId }))
		const onSuccess = (res) => {
			setPaneUpdateData((prevState) => ({ ...prevState, loading: false, paneId: '' }))
			dispatch(showSnackbarWithTimeout(getSuccessMsg(res), 1500))
		}
		const onFailure = (err) => {
			setPaneUpdateData((prevState) => ({ ...prevState, loading: false, paneId: '' }))
			dispatch(showSnackbarWithTimeout(getErrMsg(err), 1500))
		}

		dispatch(updateGlassPane(sessionId, glassCode, paneId, paneName, panePosition, prevPanePosition, isEdit, onSuccess, onFailure))
	}

	const onClickPaneDelete = (id) => {
		const onSuccess = (res) => {
			dispatch(showSnackbarWithTimeout(getSuccessMsg(res), 1500))
			dispatch(hideLoader())
		}
		const onFailure = (err) => {
			dispatch(showSnackbarWithTimeout(getErrMsg(err), 1500))
			dispatch(hideLoader())
		}
		dispatch(
			showConfirmMessage(
				'Are you sure, you want to delete this pane?',
				'',
				'Confirm',
				() => {
					dispatch(showLoader('Deleting pane. Please wait...'))
					dispatch(removeGlassPane(sessionId, glassCode, id, onSuccess, onFailure))
				},
				'Cancel',
				() => {
					dispatch(hideLoader())
				}
			)
		)
	}

	const onClickAddSticky = (e, laneId, closeForm) => {
		let data = {
			sticky_title: e?.project_title,
			pane_id: laneId,
			glass_code: glassCode,
			assigned_to: _.get(e, 'assign_to.user_id'),
			reporting_to: _.get(e, 'report_to.user_id'),
			is_approval: _.toString(e?.is_approval),
			sticky_for: 'glass',
			due_date: e.due_date ? convertLocalTimeToGmtStr(e.due_date) : '',
			sticky_group: _.get(e, 'sticky_group.sticky_group_id'),
		}

		const onSuccess = (res) => {
			dispatch(showSnackbarWithTimeout(getSuccessMsg(res), 1500))
			dispatch(hideLoader())
			closeForm()
		}
		const onFailure = (err) => {
			dispatch(hideLoader())
			dispatch(showSnackbarWithTimeout(getErrMsg(err), 2500))
		}
		dispatch(showLoader('Adding Sticky...'))
		dispatch(createSticky(glassCode, data, sessionId, onSuccess, onFailure))
	}

	const boardData = useMemo(() => {
		let lanes = []
		if (!_.isEmpty(data)) {
			_.forEach(data?.lanes || [], (lane) => {
				lanes.push({ ...lane, id: lane?.pane_id, disallowAddingCard: isGuest })
			})
		}
		//update on board
		//eventBus?.publish({ type: 'UPDATE_LANES', lanes })
		return { lanes }
	}, [data, isGuest])

	// updateGlassPanePosition
	const handleLaneDragEnd = (removedIndex, addedIndex, payload) => {
		if (removedIndex >= 0 && addedIndex >= 0) {
			const { pane_name, pane_id, is_edit } = payload
			dispatch(updateGlassPanePosition(pane_id, { ...payload, pane_position: addedIndex }))
			onClickPaneUpdate(pane_id, pane_name, addedIndex, removedIndex, is_edit)
		}
	}

	const updateStickyOnRedux = (stickyObj) => {
		dispatch(updateStickyData(stickyObj))
	}

	const updateStickyPos = (stickyObj, toPaneId, prevStickyPos, nextStickyPos) => {
		return {
			...stickyObj,
			pane_id: toPaneId,
			sticky_pos: nextStickyPos ? (prevStickyPos + nextStickyPos) / 2 : prevStickyPos + DEFAULT_STICKY_POS,
		}
	}

	const getPrevStickyDetails = (fromPaneObj, toPaneObj, stickyCode, positionToMove) => {
		let incOrDec = -1
		//check sticky moved within pane
		if (fromPaneObj?.pane_id === toPaneObj?.pane_id) {
			//get existing position of the sticky in the pane
			const fromPos = _.findIndex(fromPaneObj.cards, { sticky_code: stickyCode })
			//if sticky moved downwards then prev sticky is same position otherwise position -1
			incOrDec = fromPos < positionToMove ? 0 : -1
		}
		const prevStickyPos = positionToMove + incOrDec
		return {
			prevStickyCode: _.get(toPaneObj?.cards, `[${prevStickyPos}].sticky_code`, null),
			prevStickyPos: _.get(toPaneObj?.cards, `[${prevStickyPos}].sticky_pos`, null),
			nextStickyPos: _.get(toPaneObj?.cards, `[${prevStickyPos + 1}].sticky_pos`, null),
		}
	}

	const onStickyMoveAcrossPanes = (fromPaneId, toPaneId, stickyCode, position) => {
		const currentPane = _.find(boardData?.lanes, { id: fromPaneId })
		const stickyObj = _.find(currentPane?.cards, { id: stickyCode })
		const toPane = _.find(boardData?.lanes, { id: toPaneId })
		const { prevStickyCode, prevStickyPos, nextStickyPos } = getPrevStickyDetails(currentPane, toPane, stickyCode, position)

		const onError = (err) => {
			dispatch(showSnackbarWithTimeout(getErrMsg(err), 2500))
		}

		//update sticky position locally
		updateStickyOnRedux(updateStickyPos(stickyObj, toPane?.pane_id, prevStickyPos, nextStickyPos))
		//call sticky move api
		dispatch(moveSticky(sessionId, glassCode, stickyObj, toPaneId, prevStickyCode, null, onError))
	}

	const onStickyClick = (stickyCode) => {
		history.push(`/glassx/view/${glassCode}/${stickyCode}`)
	}

	//STICK ESC TIMER EVENT BUS
	useEffect(() => {
		var isFirstTrigger = true
		const stickyEscTimer = setInterval(() => {
			AppEventBus.dispatch(STICKY_ESC_TIMER, {
				currentDateTime: new Date(),
				stickyEscValHrs,
				stickyRemindMins,
				isFirstTrigger,
			})
			isFirstTrigger = false
		}, 1000)
		return () => {
			clearInterval(stickyEscTimer)
		}
	}, [stickyEscValHrs, stickyRemindMins])

	return (
		<div className={classes.root}>
			<BoardContextProvider
				value={{
					glassMembers,
					newCardFormSubmit: onClickAddSticky,
					layout: glassLayout,
					token,
					glassLabels,
					glassStickyGroup,
					onClickPaneDelete,
					onClickPaneUpdate,
					isEditAllowed,
					isDeleteAllowed,
					paneUpdateData,
					isOwner,
					onClickAddLane,
					addPaneLoading,
				}}
			>
				{isFetching ? (
					<LoadingIndicator />
				) : (
					<Board
						editable
						// eventBusHandle={setEventBus}
						draggable={isMobileDevice ? false : isOwner && isEditAllowed}
						data={boardData}
						laneStyle={{ backgroundColor: theme.palette.glassX.laneColor, width: 274, padding: 0 }}
						style={{ background: theme.palette.glassX.bgColor, overFlowX: 'auto' }}
						components={components}
						handleLaneDragEnd={handleLaneDragEnd}
						canAddLanes={isOwner ? isCreateAllowed : false}
						cardDraggable={!isGuest}
						onCardMoveAcrossLanes={onStickyMoveAcrossPanes}
						onCardClick={onStickyClick}
					/>
				)}
			</BoardContextProvider>
		</div>
	)
}

export default KanabanBoard
