import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import { Grid, makeStyles, Typography, Container, Button, Icon } from '@material-ui/core'
import _ from 'lodash'
import classnames from 'classnames'
import { useSelector } from 'react-redux'
import { convertGmtToLocalTime, isModuleHasPerm } from 'utils'
import { usePermission } from 'hooks/usePermission'
import { PERMISSION_TYPE, ROLE_MODULES } from 'constants/modules'

const useStyles = makeStyles((theme) => ({
	root: {
		padding: theme.spacing(3, 2, 4),
		margin: 0,
		[theme.breakpoints.down('xs')]: {
			padding: theme.spacing(2, 2, 4),
		},
	},
	label: {
		fontWeight: '500',
	},
	mb10: {
		marginBottom: theme.spacing(2),
	},
	actionBtn: {
		backgroundColor: theme.palette.primary,
		marginTop: theme.spacing(1),
		marginRight: theme.spacing(2),
		'& .MuiIcon-root': {
			paddingRight: theme.spacing(1),
		},
	},
	deleteBtn: {
		backgroundColor: theme.palette.error.main,
		color: theme.palette.common.white,
		'&:hover, &:focus': {
			backgroundColor: theme.palette.error.main,
		},
	},
	glassDescBg: {
		backgroundColor: '#f2f6fa',
		padding: theme.spacing(1),
		marginBottom: theme.spacing(2),
		[theme.breakpoints.down('sm')]: {
			padding: theme.spacing(1, 2),
		},
	},
}))

const getDisplayData = (glassObj) => ({
	// 'Objectives and Key Results': glassObj?.glass_desc,
	'Glass Name': glassObj?.glass_name,
	'Glass Code': glassObj?.glass_code,
	'Project Name': glassObj?.project_name,
	'Project Code': glassObj?.project_code,
	'Created By': _.join([glassObj?.created_by?.first_name, glassObj?.created_by?.last_name], ' '),
	'Created Date': convertGmtToLocalTime(glassObj?.created_date, 'DD MMM YYYY hh:mm a'),
	'Modified By': _.join([glassObj?.modified_by?.first_name, glassObj?.modified_by?.last_name], ' '),
	'Modified Date': convertGmtToLocalTime(glassObj?.modified_date, 'DD MMM YYYY hh:mm a'),
})

const About = ({ isOwner, onClickDeleteBtn, onClickEditBtn }) => {
	const classes = useStyles()
	const glassDetails = useSelector((state) => _.get(state, 'glass', {}))
	const displayData = useMemo(() => getDisplayData(glassDetails), [glassDetails])

	const glassPerms = usePermission(ROLE_MODULES.GLASS)
	const isEditAllowed = isOwner && isModuleHasPerm(glassPerms, PERMISSION_TYPE.EDIT)
	const isDeleteAllowed = isOwner && isModuleHasPerm(glassPerms, PERMISSION_TYPE.DELETE)

	return (
		<Container className={classes.root} maxWidth={'md'}>
			<Grid container alignItems='flex-start'>
				<React.Fragment>
					<Grid item xs={12} sm={3}>
						<Typography variant='body2' className={classes.label} gutterBottom>
							{'Objectives and Key Results'}
						</Typography>
					</Grid>
					<Grid item xs={12} sm={9} className={classes.glassDescBg}>
						<Typography variant='body2' className={classes.mb10} gutterBottom>
							{glassDetails?.glass_desc}
						</Typography>
					</Grid>
				</React.Fragment>
			</Grid>
			<Grid container>
				{_.map(_.keys(displayData), (label) => (
					<React.Fragment key={label}>
						<Grid item xs={12} sm={3}>
							<Typography variant='body2' className={classes.label} gutterBottom>
								{label}
							</Typography>
						</Grid>
						<Grid item xs={12} sm={9}>
							<Typography variant='body2' className={classes.mb10} gutterBottom>
								{displayData[label]}
							</Typography>
						</Grid>
					</React.Fragment>
				))}
				<Grid container item xs={12} justifyContent='flex-end' alignItems='flex-end'>
					{isEditAllowed && (
						<Button className={classes.actionBtn} onClick={onClickEditBtn} size='small' variant='contained' color='primary' disableElevation>
							<Icon fontSize='small'>edit</Icon> Edit
						</Button>
					)}
					{isDeleteAllowed && (
						<Button
							className={classnames(classes.actionBtn, classes.deleteBtn)}
							onClick={onClickDeleteBtn}
							size='small'
							variant='contained'
							color='primary'
							disableElevation
						>
							<Icon fontSize='small'>delete</Icon> Delete
						</Button>
					)}
				</Grid>
			</Grid>
		</Container>
	)
}

About.propTypes = {
	isOwner: PropTypes.bool.isRequired,
	onClickDeleteBtn: PropTypes.func.isRequired,
	onClickEditBtn: PropTypes.func.isRequired,
}

export default About
