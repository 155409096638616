import { API_ENDPOINTS } from '../../constants'
import Axios from 'axios'
import _ from 'lodash'
import { convertLocalTimeToGmtStr, jsonToFormData } from 'utils'
const CancelToken = Axios.CancelToken

const getUTC = (t) => (_.isUndefined(t) ? '' : _.isNull(t) ? null : convertLocalTimeToGmtStr(t))

// eslint-disable-next-line import/no-anonymous-default-export
export default {
	fetchMytaskStickies: (data) => {
		return Axios.post(
			API_ENDPOINTS.MY_TASK_STICKIES_VIEW_API,
			{ ...data },
			{ headers: { isAuthRequired: true, 'Content-Type': 'application/json' } }
		)
	},
	getMytaskFiltersList: (sessionId) => {
		return Axios.get(API_ENDPOINTS.MY_TASK_FILTERS_List, {
			headers: { 'Content-Type': 'application/json', sid: sessionId, isAuthRequired: true },
		})
	},
	getMytaskFilters: (sessionId) => {
		return Axios.get(API_ENDPOINTS.MY_TASK_FILTERS, {
			headers: { 'Content-Type': 'application/json', sid: sessionId, isAuthRequired: true },
		})
	},
	setMytaskFilters: (sessionId, data, cancelSource) => {
		return Axios.post(
			API_ENDPOINTS.MY_TASK_FILTERS,
			{ ...data },
			{ headers: { 'Content-Type': 'application/json', sid: sessionId, isAuthRequired: true }, cancelToken: cancelSource.token }
		)
	},
}
