import React, { useCallback, useEffect, useRef, useState } from 'react'
import SubTaskForm from './SubTaskForm'
import { makeStyles } from '@material-ui/core'
import SubTaskList from './SubTaskList'
import GlassApi from '../../../../../services/glass/api'
import { useDispatch } from 'react-redux'
import { hideLoader, showConfirmMessage, showErrorMessage, showLoader, showSuccessMessage } from 'services/loader/actions'
import { convertLocalTimeToGmtStr, getErrMsg, getSuccessMsg } from 'utils'
import _ from 'lodash'
import { showSnackbarWithTimeout } from 'services/snackbar/actions'
import { useFormik } from 'formik'
import * as Yup from 'yup'

import { ACTION_TYPES } from '../../../../../constants'

const useStyles = makeStyles((theme) => ({
	root: {
		padding: '0 20px',
		[theme.breakpoints.down('sm')]: {
			padding: theme.spacing(0),
		},
	},
	verticalSpacing: {
		margin: theme.spacing(2, 0),
		[theme.breakpoints.down('sm')]: {
			margin: theme.spacing(1, 1),
		},
	},
}))

const StickySchema = Yup.object().shape({
	sticky: Yup.array().nullable().required('Please Sticky to add.'),
})

const SubTask = ({ glassMembers, glassCode, stickyId, stickyDetails, history, isSocketConnected, socketRef, sessionId, isOwnerOrReporter }) => {
	const classes = useStyles()
	const dispatch = useDispatch()
	const [closeDialog, setCloseDialog] = useState(false)
	const [subTaskData, setSubTaskData] = useState({
		loading: false,
		subTaskList: [],
		totalList: 0,
		refreshOptions: true,
	})

	const { loading, subTaskList, refreshOptions, totalList } = subTaskData
	const subTaskAddListener = useRef()
	const subTaskRemoveListener = useRef()

	const addSubTasksToState = (subTaskArr) => {
		setSubTaskData((prevState) => ({
			...prevState,
			subTaskList: [...prevState?.subTaskList, ...subTaskArr],
			totalList: prevState?.totalList + subTaskArr.length,
		}))
	}

	const removeSubTasksToState = (stickyCode) => {
		setSubTaskData((prevState) => {
			const dataToUpdate = _.filter(prevState?.subTaskList, (subTask) => !_.isEqual(subTask?.sticky_code, stickyCode))
			return {
				...prevState,
				subTaskList: dataToUpdate,
				totalList: dataToUpdate.length,
			}
		})
	}

	useEffect(() => {
		if (isSocketConnected) {
			subTaskAddListener.current = (resp) => {
				addSubTasksToState(_.isArray(resp?.data) ? resp?.data : [resp?.data])
			}
			subTaskRemoveListener.current = (resp) => {
				removeSubTasksToState(resp?.data?.sticky_code)
			}
			socketRef.current?.on('sub_sticky_create', subTaskAddListener.current)
			socketRef.current?.on('sub_sticky_delete', subTaskRemoveListener.current)
		}
		return () => {
			if (isSocketConnected && subTaskAddListener.current && subTaskRemoveListener.current) {
				socketRef.current?.off('sub_sticky_create', subTaskAddListener.current)
				// eslint-disable-next-line react-hooks/exhaustive-deps
				socketRef.current?.off('sub_sticky_delete', subTaskRemoveListener.current)
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [socketRef, isSocketConnected])

	const onCreateSubtask = (value) => {
		setCloseDialog(false)
		dispatch(showLoader('Loading please wait...'))
		let formData = new FormData()
		formData.append('sticky_title', value?.sticky_title)
		value?.assign_to?.user_id && formData.append('assigned_to', _.get(value, 'assign_to.user_id', null))
		_.get(value, 'due_date', null) && formData.append('due_date', convertLocalTimeToGmtStr(_.get(value, 'due_date')))
		formData.append('glass_code', glassCode)
		formData.append('pane_id', stickyDetails?.pane_id)

		const onSuccess = (res) => {
			addSubTasksToState([_.get(res, 'data.data', {})])
			setCloseDialog(true)
			dispatch({
				type: ACTION_TYPES.ADD_GLASS_STICKY,
				payload: _.get(res, 'data.data', {}),
			})
			dispatch(
				showSuccessMessage(getSuccessMsg(res), 'Close', (e) => {
					dispatch(hideLoader())
				})
			)
		}

		const onFailure = (err) => {
			setCloseDialog(false)
			dispatch(showErrorMessage(getErrMsg(err), 'Close', () => dispatch(hideLoader())))
		}
		GlassApi.createNewSubTask(glassCode, stickyId, formData, sessionId).then(onSuccess, onFailure)
	}

	const onSubmitLinkData = (data) => {
		let stickyIds = _.map(data?.sticky, (item) => item?.sticky_code)
		dispatch(showLoader('Loading please wait...'))
		setSubTaskData((prevState) => ({ ...prevState, refreshOptions: false }))
		let formData = {
			sticky_code: stickyIds,
		}
		const onSuccess = (res) => {
			formik?.resetForm()
			addSubTasksToState(_.isArray(res?.data?.data) ? res?.data?.data : [res?.data?.data])
			dispatch(
				showSuccessMessage(getSuccessMsg(res), 'Close', (e) => {
					dispatch(hideLoader())
				})
			)
		}
		const onFailure = (err) => {
			setSubTaskData((prevState) => ({ ...prevState, refreshOptions: false }))
			dispatch(showErrorMessage(getErrMsg(err), 'Close', () => dispatch(hideLoader())))
		}
		GlassApi.setUnlinkedStickies(glassCode, stickyId, formData, sessionId).then(onSuccess, onFailure)
	}

	const getSubTaskList = useCallback(() => {
		setSubTaskData((prevState) => ({ ...prevState, loading: true, subTaskList: [], totalList: 0 }))
		const onSuccess = (res) => {
			setSubTaskData((prevState) => ({
				...prevState,
				loading: false,
				subTaskList: _.get(res, 'data.data.sub_tasks', []),
				totalList: _.get(res, 'data.data.total_record', 0),
			}))
		}
		const onFailure = (err) => {
			setSubTaskData((prevState) => ({ ...prevState, loading: false, subTaskList: [], totalList: 0 }))
			dispatch(showSnackbarWithTimeout(getErrMsg(err), 1500))
		}
		GlassApi.getSubTasks(glassCode, stickyId).then(onSuccess, onFailure)
	}, [dispatch, glassCode, stickyId])

	useEffect(() => {
		getSubTaskList()
	}, [getSubTaskList])

	const onClickDeleteMenu = (stickyCode) => {
		const onSuccess = (res) => {
			dispatch(hideLoader())
			removeSubTasksToState(res?.data?.data?.sticky_code)
			dispatch(
				showSuccessMessage(getSuccessMsg(res), 'Close', (e) => {
					// getSubTaskList()
					dispatch(hideLoader())
				})
			)
		}
		const onFailure = (err) => {
			dispatch(showErrorMessage(getErrMsg(err), 'Close', () => dispatch(hideLoader())))
		}
		const onConfirm = () => {
			dispatch(showLoader('Please wait...'))
			GlassApi.deleteSubTask(glassCode, stickyId, { sticky_code: stickyCode }, sessionId).then(onSuccess, onFailure)
		}
		dispatch(
			showConfirmMessage(`Are you sure, you want to remove this sticky from the subtask?`, '', 'Confirm', onConfirm, 'Cancel', () =>
				dispatch(hideLoader())
			)
		)
	}

	const onRowClicked = (row) => {
		history.push(`/glassx/view/${glassCode}/${row?.data?.sticky_code}`)
	}

	const formik = useFormik({
		initialValues: {
			sticky: [],
		},
		validationSchema: StickySchema,
		onSubmit: onSubmitLinkData,
	})

	return (
		<div className={classes.root}>
			<div className={classes.verticalSpacing}>
				<SubTaskForm
					glassMembers={glassMembers}
					isOwnerOrReporter={isOwnerOrReporter}
					glassCode={glassCode}
					stickyCode={stickyId}
					onCreateSubtask={onCreateSubtask}
					refreshOptions={refreshOptions}
					formik={formik}
					key={totalList || 0}
					closeDialog={closeDialog}
				/>
			</div>
			<div className={classes.verticalSpacing}>
				<SubTaskList
					glassMembers={glassMembers}
					data={subTaskList}
					totalList={totalList}
					loading={loading}
					onClickDeleteMenu={onClickDeleteMenu}
					onRowClicked={onRowClicked}
				/>
			</div>
		</div>
	)
}

export default SubTask
