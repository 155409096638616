import React from 'react'
import { Grid, TextField, Typography, Switch, FormControlLabel } from '@material-ui/core'
import Alert from '@material-ui/lab/Alert'
import PropTypes from 'prop-types'
import _ from 'lodash'
import { makeStyles } from '@material-ui/core/styles'
import { Field } from 'formik'
import classnames from 'classnames'
import File from 'components/File'

const useStyles = makeStyles((theme) => ({
	profilePic: {
		width: 170,
		height: 170,
		[theme.breakpoints.down('xs')]: {
			width: 140,
			height: 140,
			margin: '0 auto',
		},
	},
	flexRootResponsive: {
		display: 'flex',
		[theme.breakpoints.down('xs')]: {
			flexDirection: 'column',
		},
	},
	basicHeaderRight: {
		flex: 1,
		paddingLeft: theme.spacing(2),
		marginTop: theme.spacing(0.8),
		[theme.breakpoints.down('xs')]: {
			flex: 'unset',
			paddingLeft: 0,
		},
	},
	basicHeaderLeft: {
		maxWidth: 170,
		[theme.breakpoints.down('xs')]: {
			flex: 1,
			maxWidth: '100%',
			paddingBottom: theme.spacing(2),
			textAlign: 'center',
		},
	},
	textField: {
		marginBottom: theme.spacing(2.2),
		'& .Mui-disabled': {
			color: 'inherit',
			'& .MuiOutlinedInput-notchedOutline': {
				borderColor: 'transparent',
			},
		},
	},
	profileURL: {
		marginBottom: theme.spacing(2.2),
		'& .Mui-disabled': {
			color: 'inherit',
			'& .MuiOutlinedInput-notchedOutline': {
				borderColor: 'transparent',
			},
		},
		'& .MuiInputBase-input': {
			overflow: 'hidden',
			textOverflow: 'ellipsis',
			whiteSpace: 'nowrap',
		},
		marginTop: '10px',
	},
	parallelInputRoot: {
		width: 'calc(50% - 8px)',
		[theme.breakpoints.down('xs')]: {
			width: '100%',
		},
	},
	parallelInputEnd: {
		marginLeft: 16,
		[theme.breakpoints.down('xs')]: {
			marginLeft: 0,
		},
	},
	basicHeader: {
		marginBottom: theme.spacing(0.5),
		[theme.breakpoints.down('xs')]: {
			marginBottom: 0,
		},
	},
	pwdTitle: {
		textTransform: 'none',
	},
	statusIndicator: {
		marginLeft: theme.spacing(2),
		borderRadius: '50%',
		width: '10px',
		height: '10px',
		display: 'inline-block',
		marginRight: theme.spacing(1),
	},
}))

const BasicDetails = ({ isEditMode, isCreateMode, isViewMode, formik }) => {
	const classes = useStyles()

	const showFieldValue = (value) => {
		return isViewMode ? (_.isEmpty(_.trim(value)) ? '-' : value) : value
	}

	return (
		<Grid container>
			<Grid item xs={12} className={classnames(classes.flexRootResponsive, classes.basicHeader)}>
				<div className={classes.basicHeaderLeft}>
					<Field type='text' name='profile_picture'>
						{({ field, form }) => (
							<File
								disabled={isViewMode}
								value={form.values.profile_picture}
								label='Active'
								field={field}
								form={form}
								acceptsExts={['jpg', 'png', 'jpeg']}
								resolution={[240, 240]}
								placeholder={''}
								imgRatioClass={classes.profilePic}
							/>
						)}
					</Field>
				</div>
				<div className={classes.basicHeaderRight}>
					<div className={classes.flexRootResponsive}>
						<div className={classes.parallelInputRoot}>
							<Field type='text' name='first_name'>
								{({ field, form }) => (
									<TextField
										variant='outlined'
										margin='none'
										size='small'
										fullWidth
										name={field.name}
										label={'First Name*'}
										type='text'
										autoComplete='off'
										onChange={field.onChange}
										onBlur={field.onBlur}
										value={field.value || ''}
										disabled={isViewMode}
										className={classes.textField}
										helperText={form.touched[field.name] && form.errors[field.name]}
										error={Boolean(form.errors[field.name] && form.touched[field.name])}
									/>
								)}
							</Field>
						</div>
						<div className={classnames(classes.parallelInputRoot, classes.parallelInputEnd)}>
							<Field type='text' name='last_name'>
								{({ field, form }) => (
									<TextField
										variant='outlined'
										margin='none'
										size='small'
										fullWidth
										name={field.name}
										label={'Last Name'}
										type='text'
										autoComplete='off'
										onChange={field.onChange}
										onBlur={field.onBlur}
										value={showFieldValue(field.value) || ''}
										disabled={isViewMode}
										className={classes.textField}
										helperText={form.touched[field.name] && form.errors[field.name]}
										error={Boolean(form.errors[field.name] && form.touched[field.name])}
									/>
								)}
							</Field>
						</div>
					</div>
					<Field type='text' name='email'>
						{({ field, form }) => (
							<TextField
								variant='outlined'
								margin='none'
								size='small'
								fullWidth
								name={field.name}
								label={'Email address*'}
								type='text'
								autoComplete='off'
								onChange={field.onChange}
								onBlur={field.onBlur}
								value={field.value || ''}
								disabled={isViewMode || _.get(form, 'values.is_email_verified', false)}
								className={classes.textField}
								helperText={form.touched[field.name] && form.errors[field.name]}
								error={Boolean(form.errors[field.name] && form.touched[field.name])}
							/>
						)}
					</Field>
					<Field type='text' name='phone'>
						{({ field, form }) => (
							<TextField
								variant='outlined'
								margin='none'
								size='small'
								fullWidth
								name={field.name}
								label={'Phone'}
								type='text'
								autoComplete='off'
								onChange={field.onChange}
								onBlur={field.onBlur}
								value={showFieldValue(field.value) || ''}
								disabled={isViewMode}
								className={classes.textField}
								helperText={form.touched[field.name] && form.errors[field.name]}
								error={Boolean(form.errors[field.name] && form.touched[field.name])}
							/>
						)}
					</Field>
				</div>
			</Grid>
			<Grid item xs={12}>
			    <Field type='text' name='profile_url'>
					{({ field, form }) => (
						<TextField
							variant='outlined'
							margin='none'
							size='small'
							fullWidth
							name={field.name}
							label={'Profile URL'}
							type='text'
							autoComplete='off'
							onChange={field.onChange}
							onBlur={field.onBlur}
							value={showFieldValue(field.value) || ''}
							disabled={isViewMode}
							className={classes.profileURL}
							helperText={form.touched[field.name] && form.errors[field.name]}
							error={Boolean(form.errors[field.name] && form.touched[field.name])}
						/>
					)}
				</Field>
			</Grid>
			<Grid item xs={12}>
				{!isCreateMode && isEditMode && _.get(formik, 'values.is_email_verified', false) && (
					<>
						<Typography variant='overline' component='div' gutterBottom className={classes.pwdTitle}>
							Change Password
						</Typography>
						<Field type='text' name='password'>
							{({ field, form }) => (
								<TextField
									variant='outlined'
									margin='none'
									size='small'
									fullWidth
									name={field.name}
									label={'Password'}
									onChange={field.onChange}
									onBlur={field.onBlur}
									type='password'
									value={field.value}
									disabled={isViewMode}
									className={classes.textField}
									helperText={form.touched[field.name] && form.errors[field.name]}
									autoComplete='new-password'
									error={Boolean(form.errors[field.name] && form.touched[field.name])}
								/>
							)}
						</Field>
						<Field type='text' name='retype_password'>
							{({ field, form }) => (
								<TextField
									variant='outlined'
									margin='none'
									size='small'
									fullWidth
									name={field.name}
									label={'Re-type Password'}
									type='password'
									autoComplete='new-password'
									onChange={field.onChange}
									onBlur={field.onBlur}
									value={field.value}
									disabled={isViewMode}
									className={classes.textField}
									helperText={form.touched[field.name] && form.errors[field.name]}
									error={Boolean(form.errors[field.name] && form.touched[field.name])}
								/>
							)}
						</Field>
					</>
				)}

				<Field type='text' name='is_active'>
					{({ field }) =>
						isEditMode || isCreateMode ? (
							<FormControlLabel
								value='end'
								control={
									<Switch
										color='primary'
										onChange={(event) =>
											field.onChange({
												target: {
													name: 'is_active',
													value: event.target.checked,
												},
											})
										}
										checked={field.value}
										readOnly={isViewMode}
									/>
								}
								label={'Active'}
								labelPlacement='end'
							/>
						) : (
							<Typography>
								<span
									style={{
										backgroundColor: field.value ? '#82c43c' : '#fc5a5a',
									}}
									className={classes.statusIndicator}
								></span>
								{field.value ? 'Active' : 'Inactive'}
							</Typography>
						)
					}
				</Field>
				{(isCreateMode || (isEditMode && !_.get(formik, 'values.is_email_verified', false))) && (
					<Alert severity={isCreateMode || _.get(formik, 'values.is_email_verified', false) ? 'info' : 'warning'}>
						{!isCreateMode
							? 'Email address is not verified yet. Please check the login credentials in your registered email address.'
							: 'The login credentials will be sent to the user email address.'}
					</Alert>
				)}
			</Grid>
		</Grid>
	)
}

BasicDetails.propTypes = {
	isCreateMode: PropTypes.bool.isRequired,
	isEditMode: PropTypes.bool.isRequired,
	isViewMode: PropTypes.bool.isRequired,
	formik: PropTypes.object.isRequired,
}

export default BasicDetails
