import { ACTION_TYPES } from '../../../constants'
// import _ from 'lodash'
// import membersReducer from './members'
// import labelsReducer from './labels'
// import paneReducer from './panes'
// import actTypesReducer from './activityTypes'
import { getErrMsg } from 'utils'
import update from 'immutability-helper'
// import stickyListReducer from './stickyList'

const initState = {
	isFetching: true,
	isFailed: false,
	errorMessage: '',
	project_name: '',
	project_code: '',
	glass_name: '',
	glass_code: '',
	glass_desc: '',
	glass_members: [],
	settings: {},
	stickyList: [],
	stickyDetails: [],
	created_by: {},
	created_date: '',
	modified_by: '',
	modified_date: '',
	socket_session_id: '',
	selectedFilters: {},
}

const glass = (state = initState, action) => {
	console.log('action', action)
	switch (true) {
		case action.type === ACTION_TYPES.GET_MY_TASK_FILTERS_SUCCESS: {
			return {
				...state,
				selectedFilters: action.payload,
			}
		}
		case action.type === ACTION_TYPES.GET_MY_TASK_STICKIES_SUCCESS: {
			return {
				...state,
				// selectedFilters: action.payload,
			}
		}
		default: {
			return state
		}
	}
}

export default glass
