import React, { useCallback, useEffect, /*  useMemo, */ useRef, useState } from 'react'
import PropTypes from 'prop-types'
import {
	AppBar,
	Avatar,
	Box,
	Button,
	Grid,
	Icon,
	IconButton,
	ButtonGroup,
	makeStyles,
	Toolbar,
	Typography,
	useMediaQuery,
	useTheme,
} from '@material-ui/core'
import { getErrMsg, stringToHslColor } from 'utils'
import { getAvatarTxt } from 'utils'
import { Skeleton } from '@material-ui/lab'
import classnames from 'classnames'
import GlassSwitchPopover from './GlassSwitchPopover'
// import CustomSwitch from 'components/CustomSwitch'
import { GLASS_LAYOUT } from 'constants/index'
import { useDispatch } from 'react-redux'
import { updateGlassLayout } from 'services/glass/actions'
import PopoverMenus from 'components/PopoverMenus'
import GlassApi from 'services/glass/api'
import { showSnackbarWithTimeout } from 'services/snackbar/actions'
import SearchBox from 'components/SearchBox'
import _ from 'lodash'
import CustomTooltip from 'components/ToolTip'
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown'
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp'
import ClickAwayListener from '@material-ui/core/ClickAwayListener'
import Grow from '@material-ui/core/Grow'
import Paper from '@material-ui/core/Paper'
import Popper from '@material-ui/core/Popper'
import MenuItem from '@material-ui/core/MenuItem'
import MenuList from '@material-ui/core/MenuList'
import { refreshStickyList } from 'services/glass/actions'
import DashboardsApi from '../../../services/dashboards/api'

Header.propTypes = {
	title: PropTypes.string.isRequired,
	subtitle: PropTypes.string.isRequired,
	openSettings: PropTypes.func.isRequired,
	isLoading: PropTypes.bool.isRequired,
	openActivity: PropTypes.func.isRequired,
	glassLayout: PropTypes.string.isRequired,
	glassCode: PropTypes.string.isRequired,
	sessionId: PropTypes.string,
	data: PropTypes.string,
	filterMenuProps: PropTypes.bool.isRequired,
	onSearchChange: PropTypes.func.isRequired,
}

const useStyles = makeStyles((theme) => ({
	appBar: {
		height: 48,
		boxShadow: 'none',
		backgroundColor: theme.palette.common.white,
		borderBottom: `1px solid ${theme.palette.divider}`,
		'& div': {
			lineHeight: 'unset',
		},
		zIndex: 1,
		flexWrap: 'nowrap',
		[theme.breakpoints.down(993)]: {
			padding: theme.spacing(0, 1),
		},
		'& .MuiToolbar-gutters': {
			[theme.breakpoints.only('md')]: {
				padding: '0 1rem',
			},
		},
	},
	avatarWrapper: {
		width: 'unset',
	},
	avatar: {
		width: '38px',
		height: '38px',
		fontSize: '14px',
		marginRight: theme.spacing(1.5),
	},
	avatarBg: {
		backgroundColor: (props) => stringToHslColor(props.title),
	},
	titleWrapper: {
		flexGrow: '1',
		display: 'flex',
		alignItems: 'center',
		overflow: 'hidden',
		'& > div': {
			overflow: 'hidden',
		},
		'& .MuiListItem-root': {
			padding: '8px 12px',
			'& .MuiIcon-root': {
				paddingRight: '4px',
				color: 'rgba(0, 0, 0, 0.54)',
			},
		},
	},
	overflowHidden: {
		whiteSpace: 'nowrap',
		overflow: 'hidden',
		textOverflow: 'ellipsis',
	},
	mb1: {
		marginBottom: theme.spacing(0.5),
	},
	btnContainer: {
		display: 'flex',
		justifyContent: 'space-between',
	},
	btnContainerView: {
		display: 'flex',
		justifyContent: 'space-between',
		marginLeft: theme.spacing(2),
	},
	switchContainer: {
		width: 'auto',
		paddingRight: theme.spacing(1.5),
		'& Button.action-btn': {
			marginLeft: theme.spacing(1),
			border: `1px solid ${theme.palette.grey[400]}`,
		},
		// [theme.breakpoints.down(370)]: {
		// 	display: 'contents',
		// },
		[theme.breakpoints.down('sm')]: {
			paddingRight: theme.spacing(0),
			display: 'contents',
		},
	},
	actionBtn: {
		marginRight: '8px',
	},
	dropdownBtn: {
		marginLeft: theme.spacing(1),
		color: theme.palette.grey[600],
	},
	infoBtn: {
		marginLeft: theme.spacing(1),
	},
	searchBoxContainer: {
		padding: theme.spacing(0, 0.5),
		[theme.breakpoints.down(993)]: {
			flex: '1',
			padding: 0,
		},
	},
	searchWrapper: {
		[theme.breakpoints.down(993)]: {
			width: '100%',
			height: '99%',
			position: 'absolute',
			top: 0,
			left: 0,
			zIndex: '1',
			backgroundColor: theme.palette.background.paper,
			display: 'none',
			'&.show': {
				display: 'flex',
				alignItems: 'center',
				justifyContent: 'center',
			},
		},
	},
	searchBox: {
		[theme.breakpoints.down(993)]: {
			border: 'none!important',
			'&.focused, &:hover': {
				boxShadow: 'none!important',
			},
		},
	},
	titleHolder: {
		maxWidth: '18rem',
		[theme.breakpoints.down(1290)]: {
			maxWidth: '15rem',
		},
		[theme.breakpoints.down(1305)]: {
			maxWidth: '13rem',
		},
	},
	title: {
		[theme.breakpoints.down(1450)]: {
			maxWidth: '12rem',
		},
		[theme.breakpoints.down(1377)]: {
			maxWidth: '6rem',
		},
		[theme.breakpoints.between(1269, 1350)]: {
			maxWidth: '2rem',
		},
		[theme.breakpoints.down(1270)]: {
			maxWidth: '10rem',
		},
	},
	btnGrp: {
		'& .MuiButtonGroup-groupedContainedPrimary': {
			borderColor: 'transparent',
		},
		'& .MuiButtonBase-root': {
			padding: '4px',
		},
	},
	actionPopoverRoot: {
		marginTop: '8px',
	},
}))

const actionSettingMenus = [
	{
		label: 'Action',
		icon: 'history',
		actionName: 'action',
	},
	{
		label: 'Settings',
		icon: 'settings',
		actionName: 'settings',
	},
]

const options = [
	{
		label: 'Compact View',
		icon: 'view_compact_alt',
		actionName: 'compact',
	},
	{
		label: 'Grid View',
		icon: 'view_column',
		actionName: 'normal',
	},
	{
		label: 'List View',
		icon: 'table_rows',
		actionName: 'list',
	},
]

const buildDashOptions = (dashboardList) => {
	return (
		_.map(dashboardList, (dashboard) => ({
			id: _.get(dashboard, 'dashboard_id'),
			name: _.get(dashboard, 'dashboard_name'),
			is_system_generated: _.get(dashboard, 'is_system_generated'),
		})) || []
	)
}

function Header({
	isLoading,
	title,
	subtitle,
	openSettings,
	glassDesc,
	openActivity,
	dashboard,
	onClickDashboard,
	glassLayout,
	glassCode,
	sessionId,
	data,
	filterMenuProps,
	onSearchChange,
	searchText,
	totalstickies,
	gridTotalStickies,
	isListView
}) {
	const avatarTxt = getAvatarTxt(title)
	const classes = useStyles({ title: avatarTxt })
	const [popoverAncherEl, setPopoverAncherEl] = React.useState(null)
	const [popoverAncherE2, setPopoverAncherE2] = React.useState(null)
	const anchorEl = useRef()
	const moreOptionsAchorEl = useRef()
	const dispatch = useDispatch()
	const theme = useTheme()
	const [openSwitchPop, setOpenSwitchPop] = useState(false)
	const [openActionMenus, setOpenActionMenus] = useState(false)
	const [openActionSettingMenus, setOpenActionSettingMenus] = useState(false)
	const [openSearchBar, setOpenSearchBar] = useState(false)
	const [open, setOpen] = React.useState(false)
	const anchorRef = React.useRef(null)
	const [selectedIndex, setSelectedIndex] = React.useState(1)
	const [dashboardState, setDashboardState] = useState({
		isFetching: true,
		dashboardList: [],
	})
	const { isFetching, dashboardList } = dashboardState

	const loadDashboardList = useCallback(() => {
		setDashboardState({ isFetching: true, dashboardList: [] })
		DashboardsApi.fetchCustomDashboards(glassCode).then((resp) => {
			setDashboardState({
				isFetching: false,
				dashboardList: buildDashOptions(_.get(resp, 'data.data.dashboards', [])),
			})
		})
	}, [])

	useEffect(() => {
		loadDashboardList()
	}, [])

	let result =
		dashboard?.dashboard_type !== 'Default' &&
		_.find(dashboardList, function (n) {
			if (n.id === dashboard?.dashboard_id) {
				return true
			}
		})

	useEffect(() => {
		if (_.isEqual(glassLayout, 'compact')) {
			setSelectedIndex(0)
		} else if (_.isEqual(glassLayout, 'normal')) {
			setSelectedIndex(1)
		} else {
			setSelectedIndex(2)
		}
	}, [glassLayout])

	const actionMenus = [
		{
			label: 'Compact View',
			icon: 'view_compact_alt',
			actionName: 'compact',
		},
		{
			label: 'Grid View',
			icon: 'view_column',
			actionName: 'normal',
		},
		{
			label: 'List View',
			icon: 'table_rows',
			actionName: 'list',
		},
		{
			label: 'Action',
			icon: 'history',
			actionName: 'action',
		},
		{
			label: 'Settings',
			icon: 'settings',
			actionName: 'settings',
		},
		dashboard?.dashboard_config &&
			result !== undefined && {
				label: 'View Dashboard',
				icon: 'dashboard',
				actionName: 'dashboard',
			},
	].filter(Boolean)

	const toggleSwitchPopover = (e) => {
		setOpenSwitchPop(!openSwitchPop)
	}

	const toggleActionMenus = (e) => {
		if (e) {
			setPopoverAncherEl(e.currentTarget)
		}
		setOpenActionMenus(!openActionMenus)
	}

	const toggleActionSettingMenus = (e) => {
		if (e) {
			setPopoverAncherE2(e.currentTarget)
		}
		setOpenActionSettingMenus(!openActionSettingMenus)
	}

	const toggleSearchBar = (e) => {
		if (openSearchBar) {
			onSearchChange('')
		}
		setOpenSearchBar(!openSearchBar)
	}

	const performAction = (actionName) => {
		switch (actionName) {
			case 'settings':
				openSettings()
				break
			case 'action':
				openActivity()
				break
			case 'dashboard':
				onClickDashboard()
				break
			case 'compact':
				handleMenuItemClick('', 0)
				break
			case 'normal':
				handleMenuItemClick('', 1)
				break
			case 'list':
				handleMenuItemClick('', 2)
				break
			default:
				break
		}
		toggleActionMenus()
	}

	const performActionSetting = (actionName) => {
		switch (actionName) {
			case 'settings':
				openSettings()
				break
			case 'action':
				openActivity()
				break

			default:
				break
		}
		toggleActionSettingMenus()
	}

	const handleMenuItemClick = (event, index) => {
		const glassLayout =
			options[index].label === 'Compact View' ? GLASS_LAYOUT.COMPACT : options[index].label === 'Grid View' ? GLASS_LAYOUT.NORMAL : GLASS_LAYOUT.LIST
		if (glassLayout !== GLASS_LAYOUT.LIST) {
			dispatch(refreshStickyList(glassCode, searchText))
		}
		dispatch(updateGlassLayout(glassLayout))
		GlassApi.updateGlassLayout(glassCode, glassLayout, sessionId).then(
			(resp) => {},
			(err) => {
				dispatch(showSnackbarWithTimeout(getErrMsg(err), 2000))
			}
		)
		setSelectedIndex(index)
		setOpen(false)
	}

	const handleToggle = () => {
		setOpen((prevOpen) => !prevOpen)
	}

	const handleClose = (event) => {
		if (anchorRef.current && anchorRef.current.contains(event.target)) {
			return
		}
		setOpen(false)
	}

	// const compactSwitchVal = useMemo(() => glassLayout === GLASS_LAYOUT.COMPACT, [glassLayout])

	// const onChangeCompactSwitch = (e) => {
	// 	const glassLayout = e.target.checked ? GLASS_LAYOUT.COMPACT : GLASS_LAYOUT.NORMAL
	// 	dispatch(updateGlassLayout(glassLayout))
	// 	GlassApi.updateGlassLayout(glassCode, glassLayout, sessionId).then(
	// 		(resp) => {},
	// 		(err) => {
	// 			dispatch(showSnackbarWithTimeout(getErrMsg(err), 2000))
	// 		}
	// 	)
	// }

	const onChangeSearchBox = useCallback(
		_.debounce((e) => {
			onSearchChange(e.target.value)
		}, 300),
		[isListView]
	)

	const isSmallDevice = useMediaQuery(theme.breakpoints.down(1270))
	// const isMD = useMediaQuery(theme.breakpoints.down('md'))
	const isSmartPhone = useMediaQuery(theme.breakpoints.down(993))

	return (
		<AppBar className={classes.appBar} position='static' color='default'>
			<Toolbar variant='dense' disableGutters={isSmartPhone} ref={anchorEl}>
				{isLoading ? (
					<Skeleton variant='rect' className={classes.avatar} />
				) : (
					<Avatar className={classnames(classes.avatar, classes.avatarBg)} color='primary' variant='square'>
						{avatarTxt}
					</Avatar>
				)}
				{isLoading ? (
					<Box className={classes.titleWrapper}>
						<div>
							<Skeleton variant='rect' className={classes.mb1} marginbottom={0.5} width={100} height={20} />
							<Skeleton variant='rect' width={80} height={10} />
						</div>
					</Box>
				) : (
					<Box className={classes.titleWrapper}>
						<div className={classnames(classes.titleHolder, filterMenuProps.isOpen && classes.title)}>
							<Typography className={classes.overflowHidden} variant='h6' component='div' color='inherit'>
								{title}
							</Typography>
							<Typography className={classes.overflowHidden} variant='caption' component='div' color='textSecondary'>
								{subtitle}
							</Typography>
						</div>
						<IconButton onClick={toggleSwitchPopover} className={classes.dropdownBtn} size='small'>
							<Icon>arrow_drop_down</Icon>
						</IconButton>
						{!isSmartPhone && (
							<CustomTooltip title={!_.isEmpty(glassDesc) ? glassDesc : `${title} - ${subtitle}`}>
								<IconButton onClick={null} className={classes.infoBtn} size='small'>
									<Icon>info</Icon>
								</IconButton>
							</CustomTooltip>
						)}
					</Box>
				)}
				{isLoading ? (
					<>
						<Skeleton variant='rect' className={classes.actionBtn} width={80} height={20} />
						<Skeleton variant='rect' className={classes.settingsBtn} width={80} height={20} />
					</>
				) : (
					<>
						<Grid className={classes.switchContainer} container alignItems='center'>
							{!isSmartPhone & !filterMenuProps.isOpen ? (
								<Grid item>
									<Typography component='div' variant='caption' color='initial'>
										TOTAL STICKIES: {_.isEqual(glassLayout, 'list') ? `${totalstickies}` : `${gridTotalStickies}`}
									</Typography>
								</Grid>
							) : (
								<Grid item>
									<Typography component='div' variant='caption' color='initial'>
										({_.isEqual(glassLayout, 'list') ? `${totalstickies}` : `${gridTotalStickies}`})
									</Typography>
								</Grid>
							)}
							<Grid item className={classnames(classes.searchWrapper, { show: openSearchBar })}>
								{isSmartPhone && (
									<IconButton size='small' onClick={toggleSearchBar}>
										<Icon fontSize='small'>arrow_back</Icon>
									</IconButton>
								)}

								<SearchBox
									placeholder='Search'
									onChange={onChangeSearchBox}
									searchBarClassName={classes.searchBox}
									containerClassName={classes.searchBoxContainer}
									size='small'
								/>
							</Grid>
							<Grid item>
								{!isSmallDevice && dashboard?.dashboard_id && dashboard?.dashboard_config && result !== undefined && (
									<div className={classes.btnContainerView}>
										<Button size='small' variant='outlined' onClick={onClickDashboard} className={classes.actionBtn} color='primary'>
											<Icon size='small' fontSize='small'>
												dashboard
											</Icon>{' '}
											&nbsp; View Dashboard
										</Button>
									</div>
								)}
							</Grid>
							{!isSmallDevice && (
								<Grid item>
									<Box className={classes.titleWrapper}>
										<ButtonGroup variant='outlined' color='primary' ref={anchorRef} className={classes.btnGrp} aria-label='split button'>
											<Button size='small' variant='outlined' onClick={handleToggle} color='primary'>
												{/* {options[selectedIndex]} */}

												<Icon fontSize='small'>{options[selectedIndex].icon}</Icon>
											</Button>
											{/* <Button
												color='primary'
												size='small'
												variant='outlined'
												aria-controls={open ? 'split-button-menu' : undefined}
												aria-expanded={open ? 'true' : undefined}
												aria-label='select merge strategy'
												aria-haspopup='menu'
												onClick={handleToggle}
											>
												{open ? <ArrowDropUpIcon fontSize='small' /> : <ArrowDropDownIcon fontSize='small' />}
											</Button> */}
										</ButtonGroup>
										<Popper open={open} anchorEl={anchorRef.current} role={undefined} transition disablePortal placement='bottom-end'>
											{({ TransitionProps, placement }) => (
												<Grow
													{...TransitionProps}
													style={{
														transformOrigin: placement === 'center bottom',
													}}
												>
													<Paper>
														<ClickAwayListener onClickAway={handleClose}>
															<MenuList id='split-button-menu'>
																{options.map((option, index) => (
																	<MenuItem
																		key={option.actionName}
																		selected={index === selectedIndex}
																		onClick={(event) => handleMenuItemClick(event, index)}
																	>
																		<Icon fontSize='small'>{option.icon}</Icon>
																		{option.label}
																	</MenuItem>
																))}
															</MenuList>
														</ClickAwayListener>
													</Paper>
												</Grow>
											)}
										</Popper>
									</Box>
								</Grid>
							)}

							{isSmartPhone && (
								<Grid item>
									<IconButton className='action-btn' size='small' onClick={toggleSearchBar}>
										<Icon fontSize='small'>search</Icon>
									</IconButton>
								</Grid>
							)}
							{isSmallDevice && (
								<>
									<Grid item>
										<IconButton
											className='action-btn'
											aria-describedby={'popover'}
											onClick={toggleActionMenus}
											size='small'
											ref={(ref) => (moreOptionsAchorEl.current = ref)}
										>
											<Icon fontSize='small'>more_horiz</Icon>
										</IconButton>
									</Grid>
									<PopoverMenus
										open={openActionMenus}
										id={'popover'}
										menus={actionMenus}
										anchorEl={popoverAncherEl}
										onClickAction={performAction}
										onClose={toggleActionMenus}
									/>
								</>
							)}
						</Grid>
						{!isSmallDevice && (
							<>
								<Grid item>
									<IconButton size='small' edge='end' onClick={toggleActionSettingMenus}>
										<Icon>more_vert</Icon>
									</IconButton>
								</Grid>
								<PopoverMenus
									open={openActionSettingMenus}
									id={'popover'}
									menus={actionSettingMenus}
									anchorEl={popoverAncherE2}
									onClickAction={performActionSetting}
									onClose={toggleActionSettingMenus}
									className={classes.actionPopoverRoot}
								/>
							</>
						)}
						{/* {isSmallDevice ? (
							<></>
						) : (
							<div className={classes.btnContainer}>
								<Button size='small' variant='outlined' onClick={openActivity} className={classes.actionBtn} color='primary'>
									Action
								</Button>
								<Button size='small' variant='outlined' onClick={openSettings} className={classes.settingsBtn} color='primary'>
									Settings
								</Button>
							</div>
						)} */}
					</>
				)}
			</Toolbar>

			<GlassSwitchPopover anchorEl={anchorEl.current} open={openSwitchPop} handleClose={toggleSwitchPopover} />
		</AppBar>
	)
}

export default Header
