// import React, { useEffect, useState, useCallback } from 'react'
// import PropTypes from 'prop-types'
// import {
// 	Button,
// 	Checkbox,
// 	Divider,
// 	FormControlLabel,
// 	FormGroup,
// 	Grid,
// 	Icon,
// 	Link,
// 	List,
// 	ListItem,
// 	ListItemAvatar,
// 	ListItemText,
// 	makeStyles,
// 	Typography,
// 	Hidden,
// 	Avatar,
// 	IconButton,
// 	Switch,
// } from '@material-ui/core'
// import _ from 'lodash'
// import moment from 'moment'
// import SaveResultPopup from './SaveResultPopup'
// import PopoverMenus from 'components/PopoverMenus'
// import DiscoverxApi from 'services/discoverx/api'
// import { PERMISSION_TYPE } from 'constants/modules'
// import { useDispatch, useSelector } from 'react-redux'
// import { hideLoader, showErrorMessage, showLoader, showSuccessMessage } from 'services/loader/actions'
// import { convertLocalTimeToGmtStr, getErrMsg, getSuccessMsg } from 'utils'
// import { useFormik } from 'formik'
// import * as Yup from 'yup'
// import { getImgUrl } from 'utils'
// import { API_ENDPOINTS } from 'constants/index'
// import classNames from 'classnames'

// const useStyles = makeStyles((theme) => ({
// 	root: {
// 		paddingBottom: theme.spacing(1),
// 	},
// 	cbSection: {
// 		minWidth: 'unset',
// 		margin: 0,
// 	},
// 	imgSection: {
// 		minWidth: 'unset',
// 		margin: 0,
// 	},
// 	title: {
// 		fontWeight: '500',
// 		wordBreak: 'break-word',
// 		display: '-webkit-box',
// 		'-webkit-line-clamp': 2,
// 		'-webkit-box-orient': 'vertical',
// 		overflow: 'hidden',
// 	},
// 	docType: {
// 		backgroundColor: theme.palette.primary.main,
// 		color: theme.palette.common.white,
// 		padding: '2px 6px',
// 		fontWeight: '500',
// 		borderRadius: '4px',
// 		marginLeft: theme.spacing(0.5),
// 		fontSize: '12px',
// 	},
// 	timeInfo: {
// 		marginTop: theme.spacing(0.7),
// 		lineHeight: '10px',
// 		'& span': {
// 			verticalAlign: 'middle',
// 			marginRight: theme.spacing(0.3),
// 		},
// 	},
// 	listItemRoot: {
// 		// alignItems: 'center',
// 		[theme.breakpoints.up('md')]: {
// 			// marginBottom:theme.spacing(1.5),
// 		},
// 		'& .MuiListItemText-multiline': {
// 			margin: 0,
// 		},
// 	},
// 	listRootWithoutCheckbox: {
// 		paddingTop: 0,
// 		paddingBottom: 0,
// 		'& .MuiListItemText-multiline': {
// 			margin: 8,
// 		},
// 	},
// 	desc: {
// 		display: '-webkit-box',
// 		'-webkit-line-clamp': 2,
// 		'-webkit-box-orient': 'vertical',
// 		overflow: 'hidden',
// 		width: '98%',
// 	},
// 	checkbox: {
// 		padding: '25px 6px 20px 6px',
// 		borderRadius: '0',
// 		'&:hover': {
// 			backgroundColor: 'transparent',
// 		},
// 	},
// 	infoTitle: {
// 		marginBottom: theme.spacing(1),
// 	},
// 	selectAllRoot: {
// 		padding: theme.spacing(0.5, 1),
// 		[theme.breakpoints.up('md')]: {
// 			width: 'calc(100% + 12px) !important',
// 		},
// 		'& .MuiFormControlLabel-root': {
// 			'& .MuiTypography-body1': {
// 				color: theme.palette.grey[500],
// 				fontSize: 14,
// 			},
// 		},
// 	},
// 	allpagesLink: {
// 		fontSize: 14,
// 		display: 'block',
// 		marginTop: theme.spacing(2),
// 		cursor: 'pointer',
// 	},
// 	selectedContent: {
// 		display: 'block',
// 		marginTop: theme.spacing(2),
// 	},
// 	exportBtn: {
// 		display: 'flex',
// 		alignItems: 'start',
// 		justifyContent: 'flex-end',
// 		textAlign: 'right',
// 		padding: '4px 0px 4px 4px !important',
// 		// '& .MuiButton-containedSizeSmall': {
// 		// 	margin: theme.spacing(0.5),
// 		// },
// 	},
// 	saveBtn: {
// 		marginLeft: theme.spacing(2),
// 		textTransform: 'capitalize',
// 		fontWeight: 'normal',
// 		color: theme.palette.primary.main,
// 		'&:hover': {
// 			backgroundColor: 'transparent',
// 			color: theme.palette.primary.main,
// 		},
// 	},
// 	moreBtnHolder: {
// 		display: 'flex',
// 		alignItems: 'center',
// 		justifyContent: 'center',
// 		paddingLeft: '1rem',
// 	},
// 	moreBtn: {
// 		minWidth: 'unset',
// 		// marginLeft: theme.spacing(2),
// 		textTransform: 'capitalize',
// 		fontWeight: 'normal',
// 		color: theme.palette.grey[600],
// 		'&:hover': {
// 			backgroundColor: 'transparent',
// 			color: theme.palette.primary.main,
// 		},
// 	},
// 	sortBtn: {
// 		// display: 'block',
// 		// marginLeft: 'auto',
// 		width: '100%',
// 		textTransform: 'capitalize',
// 		fontWeight: 'normal',
// 		color: theme.palette.grey[600],
// 		padding: '4px 10px',
// 		'&:hover': {
// 			backgroundColor: 'transparent',
// 			color: theme.palette.primary.main,
// 		},
// 		'& .MuiIcon-fontSizeSmall': {
// 			fontSize: '1.25rem',
// 		},
// 		[theme.breakpoints.down('sm')]: {
// 			padding: '4px 5px',
// 		},
// 		[theme.breakpoints.only('sm')]: {
// 			textAlign: 'right',
// 		},
// 	},
// 	sortedValBtn: {
// 		'& .MuiButton-label': {
// 			width: '95%',
// 			overflow: 'hidden',
// 			whiteSpace: 'nowrap',
// 			display: 'block',
// 			textOverflow: 'ellipsis',

// 			[theme.breakpoints.down('sm')]: {
// 				lineHeight: 'unset',
// 			},
// 			[theme.breakpoints.down(330)]: {
// 				width: '65%',
// 			},
// 		},
// 	},
// 	orderByHolder: {
// 		flexWrap: 'nowrap',
// 	},
// 	whitelistBtn: {
// 		marginLeft: theme.spacing(2),
// 		textTransform: 'capitalize',
// 		color: theme.palette.error.main,
// 		'&:hover': {
// 			backgroundColor: 'transparent',
// 			color: theme.palette.error.main,
// 		},
// 	},
// 	infoSection: {
// 		display: 'flex',
// 		// justifyContent: 'space-between',
// 		paddingRight: theme.spacing(2),
// 		alignItems: 'center',
// 	},
// 	resultImg: {
// 		maxWidth: 150,
// 		width: '100%',
// 		marginTop: theme.spacing(1),
// 	},
// 	square: {
// 		width: '125px',
// 		height: '100px',
// 		// marginTop: '6px',
// 		marginRight: '5px',
// 		[theme.breakpoints.down('sm')]: {
// 			width: '75px',
// 			height: '50px',
// 		},
// 	},
// 	playIcon: {
// 		position: 'absolute',
// 		bottom: 10,
// 		right: 10,
// 		color: '#fff',
// 	},
// 	menuSectionHolder: {
// 		display: 'flex',
// 		flexDirection: 'column',
// 		alignItems: 'start',
// 	},
// 	menuSection: {
// 		display: 'flex',
// 		justifyContent: 'space-between',
// 		width: '100%',
// 		'& .MuiFormGroup-root': {
// 			flexWrap: 'nowrap',
// 		},
// 	},
// 	upDownBtn: {
// 		[theme.breakpoints.down('xs')]: {
// 			padding: '5px',
// 		},
// 	},
// 	sortPopover: {
// 		'& .MuiPaper-root': {
// 			minWidth: '5rem',
// 			'& .MuiListItem-root': {
// 				justifyContent: 'center',
// 			},
// 		},
// 	},
// 	btnContainer: {
// 		display: 'flex',
// 		alignItems: 'center',
// 		flexDirection: 'row',
// 		'& .MuiIconButton-root': {
// 			padding: '6px',
// 			'&:hover': {
// 				color: theme.palette.primary.main,
// 			},
// 		},
// 	},
// }))

// ResultsList.propTypes = {
// 	isMobileView: PropTypes.bool.isRequired,
// 	// data: PropTypes.arrayOf(
// 	// 	PropTypes.shape({
// 	// 		data_source: PropTypes.string,
// 	// 		date: PropTypes.string,
// 	// 		description: PropTypes.string,
// 	// 		doc_type: PropTypes.string,
// 	// 		id: PropTypes.string,
// 	// 		title: PropTypes.string,
// 	// 		url: PropTypes.string,
// 	// 	})
// 	// ),
// 	data: PropTypes.array,
// 	onClickResult: PropTypes.func,
// 	onClickCheckbox: PropTypes.func,
// 	selectedList: PropTypes.array,
// }

// const savedResultsSchema = Yup.object().shape({
// 	category: Yup.string().trim().required('Please Select any Category'),
// 	resultName: Yup.string().trim().required('Result name is required'),
// })

// // const capitalizeFirstLetter = (string) => {
// // 	if (string?.charAt(0) === '_') {
// // 		let removeChar = string?.slice(1)
// // 		return removeChar?.charAt(0).toUpperCase() + removeChar.slice(1)
// // 	}
// // 	return string?.charAt(0).toUpperCase() + string?.slice(1)
// // }

// function ResultsList({
// 	isMobileView,
// 	data,
// 	totalData,
// 	onClickResult,
// 	allDataSelected,
// 	onClickCheckbox,
// 	selectedList,
// 	checkAllSelected,
// 	onAllDataClick,
// 	isAllChecked,
// 	onClickExport,
// 	onClickWishList,
// 	onClickSavedSearch,
// 	onClickAnalyse,
// 	query,
// 	updateFunc,
// 	filterData,
// 	activeFilters,
// 	sorting_details,
// 	sortable_fields,
// 	filters,
// 	isFiltersOpen,
// }) {
// 	const classes = useStyles()
// 	const [selectedIds, setSelectedIds] = useState([])
// 	const [selectedDsIds, setSelectedDsIds] = useState([])
// 	const [showSavedPopup, setShowSavedPopup] = useState(false)
// 	const reduxDispatch = useDispatch()
// 	const [anchorEl, setAnchorEl] = React.useState(null)
// 	const [sortAnchorEl, setSortAnchorEl] = React.useState(null)
// 	const accessToken = useSelector((state) => _.get(state, 'session.authToken', ''))
// 	const activityState = sorting_details?.field
// 	const activityIcon = sorting_details?.sort_by === 'asc' ? 'arrow_downward' : 'arrow_upward'

// 	const filterLength = activeFilters?.common?.data_source?.length

// 	useEffect(() => {
// 		let tempIds = []
// 		let tempDsIds = []
// 		_.map(selectedList, (item) => {
// 			tempIds.push(item.id)
// 			tempDsIds.push(item.ds_id)
// 		})
// 		setSelectedIds(tempIds)
// 		setSelectedDsIds(tempDsIds)
// 	}, [selectedList])

// 	const toggleSavedResults = () => {
// 		setShowSavedPopup(!showSavedPopup)
// 	}
// 	const toggleMoreBtn = (event) => {
// 		setAnchorEl(event.currentTarget)
// 	}

// 	const toggleSortBtn = (event) => {
// 		setSortAnchorEl(event.currentTarget)
// 	}

// 	const onSubmitSavedResults = (e, filter) => {
// 		reduxDispatch(showLoader('Loading please wait...'))

// 		let tempFilterDataForAPI = {}
// 		_.map(filterData || {}, (o, d) => {
// 			if (!_.isEmpty(_.get(activeFilters, o?.path))) {
// 				if (o?.type === 'date') {
// 					let temp = {}
// 					if (!!_.get(activeFilters, o?.path)?.startDate && !!_.get(activeFilters, o?.path)?.endDate) {
// 						temp.from = convertLocalTimeToGmtStr(_.get(activeFilters, o?.path)?.startDate)
// 						temp.to = convertLocalTimeToGmtStr(_.get(activeFilters, o?.path)?.endDate)
// 						_.set(tempFilterDataForAPI, o?.path, {
// 							value: temp,
// 							...(o?.additionalDataForFilterApply || {}),
// 						})
// 					}
// 					return true
// 				}
// 				_.set(tempFilterDataForAPI, o?.path, {
// 					value: _.get(activeFilters, o?.path),
// 					...(o?.additionalDataForFilterApply || {}),
// 				})
// 			}
// 		})

// 		let data = {
// 			name: e?.resultName,
// 			query: query,
// 			filter_data: [tempFilterDataForAPI],
// 			category_id: e?.category,
// 		}

// 		const onSuccess = (res) => {
// 			reduxDispatch(
// 				showSuccessMessage(getSuccessMsg(res), 'Close', (e) => {
// 					reduxDispatch(hideLoader())
// 					toggleSavedResults()
// 					formik?.resetForm()
// 				})
// 			)
// 		}
// 		const onFailure = (err) => {
// 			reduxDispatch(showErrorMessage(getErrMsg(err), 'Close', () => reduxDispatch(hideLoader())))
// 		}
// 		DiscoverxApi.createSavedSearch(data).then(onSuccess, onFailure)
// 	}

// 	const actionMenus = [
// 		{
// 			label: 'My Wishlist',
// 			icon: 'favorite',
// 			actionName: PERMISSION_TYPE.WISHLIST,
// 		},
// 		{
// 			label: 'My Saved Searches',
// 			icon: 'bookmark',
// 			actionName: PERMISSION_TYPE.SAVED_SEARCH,
// 		},
// 	]

// 	const sortable = sortable_fields.map((item) => {
// 		const container = {}

// 		container.label = item
// 		// container.icon = sorting_details?.field === item ? (sorting_details?.sort_by === 'asc' ? 'arrow_downward' : 'arrow_upward') : 'arrow_downward'
// 		container.actionName = 'SORT'

// 		return container
// 	})

// 	sortable?.unshift({ label: 'None', actionName: 'SORT' })

// 	// console.log(capitalizeFirstLetter('_gas'))

// 	const mobileActionMenus = [
// 		!_.isEmpty(selectedList) || allDataSelected
// 			? {
// 					label: 'Export Selected',
// 					icon: 'file_upload',
// 					actionName: PERMISSION_TYPE.EXPORT,
// 			  }
// 			: null,

// 		{
// 			label: 'Save result',
// 			icon: 'bookmark',
// 			actionName: PERMISSION_TYPE.SAVE,
// 		},
// 		{
// 			label: 'My Wishlist',
// 			icon: 'favorite',
// 			actionName: PERMISSION_TYPE.WISHLIST,
// 		},
// 		{
// 			label: 'My Saved Searches',
// 			icon: 'bookmark',
// 			actionName: PERMISSION_TYPE.SAVED_SEARCH,
// 		},
// 		// !_.isEmpty(selectedList) || allDataSelected
// 		// 	? {
// 		// 			label: 'Analyse',
// 		// 			icon: 'analytics',
// 		// 			actionName: PERMISSION_TYPE.ANALYSE,
// 		// 	  }
// 		// 	: null,
// 	]

// 	const fomatedActionItems = isMobileView ? _.compact(mobileActionMenus) : actionMenus

// 	const handleCloseMenu = () => {
// 		setAnchorEl(null)
// 		setSortAnchorEl(null)
// 	}

// 	const sortBy = (field, type) => {
// 		if (field === 'None') {
// 			updateFunc(undefined, { field: '', sort_by: '', limit: 0 })
// 		} else {
// 			updateFunc(undefined, { field, sort_by: type === 'arrow_downward' ? 'desc' : 'asc', limit: 0 })
// 		}
// 	}

// 	var singleDsSort = []
// 	_.forEach(filters, function (obj) {
// 		if (obj.category === 'common') singleDsSort.push(obj)
// 	})
// 	// console.log(_.isEmpty(sorting_details), 'active')

// 	const performAction = (actionName) => {
// 		switch (actionName) {
// 			case PERMISSION_TYPE.EXPORT:
// 				onClickExport()
// 				break
// 			case PERMISSION_TYPE.SAVE:
// 				toggleSavedResults()
// 				break
// 			case PERMISSION_TYPE.WISHLIST:
// 				onClickWishList()
// 				break
// 			case PERMISSION_TYPE.SAVED_SEARCH:
// 				onClickSavedSearch()
// 				break
// 			case 'SORT':
// 				sortBy()
// 				break
// 			case PERMISSION_TYPE.ANALYSE:
// 				onClickAnalyse()
// 				break
// 			default:
// 				break
// 		}
// 		handleCloseMenu()
// 	}

// 	const formik = useFormik({
// 		initialValues: {
// 			category: '',
// 			resultName: '',
// 		},
// 		onSubmit: (e) => {
// 			onSubmitSavedResults(e)
// 		},
// 		validationSchema: savedResultsSchema,
// 	})

// 	const getImageUrl = useCallback(
// 		(result) => {
// 			const defaultImageFilePath = _.get(result, 'default_image_file_path')
// 			const imgUrl = _.get(result, 'img_url')
// 			return _.isEmpty(imgUrl)
// 				? _.isEmpty(defaultImageFilePath)
// 					? null
// 					: getImgUrl(accessToken, defaultImageFilePath)
// 				: API_ENDPOINTS.CORS_API + (_.isArray(imgUrl) ? imgUrl[0] : imgUrl)
// 		},
// 		[accessToken]
// 	)

// 	return (
// 		<div className={classes.root}>
// 			<Grid container className={classes.selectAllRoot} justifyContent='space-between' spacing={1}>
// 				<Grid item xs={5} md={9} lg={8} className={classes.menuSectionHolder}>
// 					<div className={classes.menuSection}>
// 						<FormGroup row>
// 							<FormControlLabel
// 								control={
// 									<Checkbox
// 										checked={isAllChecked || allDataSelected}
// 										size='small'
// 										disableTouchRipple
// 										onChange={(event) => checkAllSelected(event, data)}
// 									/>
// 								}
// 								label='Select All'
// 							/>
// 							{!isMobileView && (
// 								<Button size='small' className={classes.saveBtn} onClick={toggleSavedResults}>
// 									<Icon fontSize='small'>bookmark</Icon> Save result
// 								</Button>
// 							)}
// 						</FormGroup>
// 						{!isMobileView && (singleDsSort[0]?.defaultOptions?.length <= 1 || (filterLength !== undefined && filterLength === 1)) && (
// 							<div className={classes.btnContainer} style={{ maxWidth: isFiltersOpen ? '140px' : 'unset' }}>
// 								<Button
// 									size='small'
// 									className={classNames(classes.sortBtn, !_.isEmpty(sorting_details) && classes.sortedValBtn)}
// 									onClick={toggleSortBtn}
// 									startIcon={_.isEmpty(sorting_details) && <Icon fontSize='small'>sort</Icon>}
// 								>
// 									{_.isEmpty(sorting_details) ? 'Sort by' : _.startCase(sorting_details.field)}
// 								</Button>
// 								{!_.isEmpty(sorting_details) && (
// 									<IconButton onClick={() => sortBy(activityState, activityIcon)}>
// 										<Icon onClick={() => sortBy(activityState, activityIcon)} fontSize='small'>
// 											{activityIcon}
// 										</Icon>
// 									</IconButton>
// 								)}
// 							</div>
// 						)}

// 						{!isMobileView && (
// 							<div className={classes.btnContainer}>
// 								<Button size='small' className={classes.moreBtn} onClick={toggleMoreBtn}>
// 									<Icon fontSize='small'>more_vert</Icon> More
// 								</Button>
// 							</div>
// 						)}
// 						{!isMobileView && (
// 							<div>
// 								<FormControlLabel value='start' control={<Switch color='primary' />} label='Grid View' labelPlacement='start' />
// 							</div>
// 						)}
// 					</div>
// 					<PopoverMenus
// 						open={Boolean(sortAnchorEl)}
// 						id={'1'}
// 						menus={sortable}
// 						anchorEl={sortAnchorEl}
// 						onClickAction={sortBy}
// 						onClose={handleCloseMenu}
// 						sortBy={sortBy}
// 						activityState={activityState}
// 						activityIcon={activityIcon}
// 						className={classes.sortPopover}
// 					/>
// 					<PopoverMenus
// 						open={Boolean(anchorEl)}
// 						id={'1'}
// 						menus={fomatedActionItems}
// 						anchorEl={anchorEl}
// 						onClickAction={performAction}
// 						onClose={handleCloseMenu}
// 					/>
// 					<Hidden smDown>
// 						<Divider />
// 					</Hidden>
// 					{(isAllChecked || allDataSelected) && (
// 						<>
// 							{allDataSelected ? (
// 								<Typography color='primary' variant='caption' className={classes.selectedContent}>
// 									{totalData} data Selected!
// 								</Typography>
// 							) : (
// 								<Link onClick={onAllDataClick} className={classes.allpagesLink}>
// 									Select results of all pages
// 								</Link>
// 							)}
// 						</>
// 					)}
// 				</Grid>
// 				<Grid item xs={7} md={3} lg={4} className={classes.exportBtn}>
// 					{!isMobileView ? (
// 						<>
// 							<Button
// 								variant='contained'
// 								size='small'
// 								onClick={onClickExport}
// 								disabled={_.isEmpty(selectedList) && !allDataSelected}
// 								color='primary'
// 								disableElevation
// 							>
// 								Export Selected
// 							</Button>
// 							{/* <Button
// 								variant='contained'
// 								size='small'
// 								onClick={onClickAnalyse}
// 								disabled={_.isEmpty(selectedList) && !allDataSelected}
// 								color='primary'
// 								disableElevation
// 							>
// 								Analyse
// 							</Button> */}
// 						</>
// 					) : (
// 						<Grid item container xs={12} direction='row' alignItems='center'>
// 							<Grid item container xs={10} direction='row' alignItems='center' className={classes.orderByHolder}>
// 								{(singleDsSort[0]?.defaultOptions.length <= 1 || (filterLength !== undefined && filterLength === 1)) && (
// 									<Grid item xs={10}>
// 										<Button
// 											size='small'
// 											className={classNames(classes.sortBtn, !_.isEmpty(sorting_details) && classes.sortedValBtn)}
// 											onClick={toggleSortBtn}
// 											startIcon={_.isEmpty(sorting_details) && <Icon fontSize='small'>sort</Icon>}
// 										>
// 											{_.isEmpty(sorting_details) ? 'Sort by' : _.startCase(sorting_details.field)}
// 										</Button>
// 									</Grid>
// 								)}
// 								{!_.isEmpty(sorting_details) && (
// 									<Grid item xs={2}>
// 										<IconButton onClick={() => sortBy(activityState, activityIcon)} className={classes.upDownBtn}>
// 											<Icon onClick={() => sortBy(activityState, activityIcon)} fontSize='small'>
// 												{activityIcon}
// 											</Icon>
// 										</IconButton>
// 									</Grid>
// 								)}
// 							</Grid>
// 							<Grid item xs={2} className={classes.moreBtnHolder}>
// 								<Button size='small' className={classes.moreBtn} onClick={toggleMoreBtn}>
// 									<Icon fontSize='small'>more_vert</Icon>
// 								</Button>
// 								<PopoverMenus
// 									open={Boolean(anchorEl)}
// 									id={'1'}
// 									menus={fomatedActionItems}
// 									anchorEl={anchorEl}
// 									onClickAction={performAction}
// 									onClose={handleCloseMenu}
// 								/>
// 							</Grid>
// 						</Grid>
// 					)}
// 					{/* analytics */}
// 				</Grid>
// 			</Grid>
// 			<Hidden mdUp>
// 				<Divider />
// 			</Hidden>
// 			<List className={classes.root}>
// 				{_.map(data, (result, idx) => {
// 					const isChecked = _.indexOf(selectedIds, result?.id) !== -1 && _.indexOf(selectedDsIds, result?.ds_id) !== -1
// 					return (
// 						<ListItem
// 							onClick={(e) => {
// 								onClickResult && onClickResult(e, result)
// 							}}
// 							classes={{ root: classes.listItemRoot }}
// 							key={_.get(result, 'id') + idx}
// 							disableGutters
// 							alignItems='flex-start'
// 							button
// 						>
// 							<ListItemAvatar className={classes.cbSection}>
// 								<Checkbox
// 									checked={isChecked || allDataSelected}
// 									onClick={(e) => {
// 										onClickCheckbox(e, result)
// 									}}
// 									className={classes.checkbox}
// 									size='small'
// 									disableTouchRipple
// 								/>
// 							</ListItemAvatar>
// 							<ListItemText
// 								primary={
// 									<React.Fragment>
// 										<Typography className={classes.infoTitle} variant='caption' color='textSecondary'>
// 											{_.get(result, 'data_source')} &nbsp;
// 											<span className={classes.docType}>{_.get(result, 'doc_type')}</span>
// 										</Typography>
// 										<Typography variant={isMobileView ? 'body2' : 'body1'} fontWeight='bold' color='textPrimary' className={classes.title}>
// 											{_.get(result, 'title')}
// 										</Typography>
// 										{/* <Typography
// 											variant={isMobileView ? 'body2' : 'body1'}
// 											fontWeight='bold'
// 											color='textPrimary'
// 											dangerouslySetInnerHTML={{
// 												__html: _.get(result, 'title'),
// 											}}
// 											className={classes.title}
// 										/> */}
// 									</React.Fragment>
// 								}
// 								secondary={
// 									<React.Fragment>
// 										<Typography component='div' gutterBottom className={classes.desc} variant='body2' color='textSecondary'>
// 											{_.get(result, 'description')}
// 										</Typography>
// 										<div className={classes.infoSection}>
// 											{_.get(result, 'date') && (
// 												<Typography component='span' className={classes.timeInfo} variant='caption'>
// 													<Icon fontSize='inherit'>query_builder</Icon>
// 													<span>{moment(_.get(result, 'date')).format('DD MMMM YYYY')}</span>
// 												</Typography>
// 											)}
// 										</div>
// 									</React.Fragment>
// 								}
// 							/>
// 							{!isMobileView && !_.isEmpty(getImageUrl(result)) && (
// 								<ListItemAvatar className={classes.imgSection}>
// 									<Avatar variant='square' className={classes.square} src={getImageUrl(result)}>
// 										<Icon>image</Icon>
// 									</Avatar>
// 								</ListItemAvatar>
// 							)}
// 							{!isMobileView && !_.isEmpty(result?.video_thumbnail_url) && (
// 								<ListItemAvatar className={classes.imgSection}>
// 									<Avatar
// 										variant='square'
// 										className={classes.square}
// 										src={_.isArray(result?.video_thumbnail_url) ? result?.video_thumbnail_url[0] : result?.video_thumbnail_url}
// 									>
// 										{_.startCase(_.slice(result?.title, 0, 1))}
// 									</Avatar>
// 									<Icon className={classes.playIcon}>play_circle_outline</Icon>
// 								</ListItemAvatar>
// 							)}
// 						</ListItem>
// 					)
// 				})}
// 			</List>
// 			<SaveResultPopup formik={formik} open={showSavedPopup} handleClose={toggleSavedResults} isMobileView={isMobileView} />
// 		</div>
// 	)
// }

// export default ResultsList

import React, { useEffect, useState, useCallback, useMemo } from 'react'
import PropTypes from 'prop-types'
import {
	Button,
	Checkbox,
	Divider,
	FormControlLabel,
	FormGroup,
	Grid,
	Icon,
	Link,
	List,
	ListItem,
	ListItemAvatar,
	ListItemText,
	makeStyles,
	Typography,
	Hidden,
	Avatar,
	IconButton,
	Switch,
	TableCell,
	TableBody,
	TableRow,
	Table,
	TableContainer,
	Paper,
	TableHead,
} from '@material-ui/core'
import _ from 'lodash'
import moment from 'moment'
import SaveResultPopup from './SaveResultPopup'
import PopoverMenus from 'components/PopoverMenus'
import DiscoverxApi from 'services/discoverx/api'
import { PERMISSION_TYPE, ROLE_MODULES } from 'constants/modules'
import { useDispatch, useSelector } from 'react-redux'
import { hideLoader, showErrorMessage, showLoader, showSuccessMessage } from 'services/loader/actions'
import { convertLocalTimeToGmtStr, getErrMsg, getSuccessMsg } from 'utils'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { getImgUrl } from 'utils'
import AgGridCustom from 'components/AgGridCustom'
import { API_ENDPOINTS } from 'constants/index'
import classNames from 'classnames'
import { usePermission } from 'hooks/usePermission'
// import Table from './Table/index'

const useStyles = makeStyles((theme) => ({
	root: {
		paddingBottom: theme.spacing(1),
	},
	cbSection: {
		minWidth: 'unset',
		margin: 0,
	},
	imgSection: {
		minWidth: 'unset',
		margin: 0,
	},
	title: {
		fontWeight: '500',
		wordBreak: 'break-word',
		display: '-webkit-box',
		'-webkit-line-clamp': 2,
		'-webkit-box-orient': 'vertical',
		overflow: 'hidden',
	},
	docType: {
		backgroundColor: theme.palette.primary.main,
		color: theme.palette.common.white,
		padding: '2px 6px',
		fontWeight: '500',
		borderRadius: '4px',
		marginLeft: theme.spacing(0.5),
		fontSize: '12px',
	},
	timeInfo: {
		marginTop: theme.spacing(0.7),
		lineHeight: '10px',
		'& span': {
			verticalAlign: 'middle',
			marginRight: theme.spacing(0.3),
		},
	},
	listItemRoot: {
		// alignItems: 'center',
		[theme.breakpoints.up('md')]: {
			// marginBottom:theme.spacing(1.5),
		},
		'& .MuiListItemText-multiline': {
			margin: 0,
		},
	},
	listRootWithoutCheckbox: {
		paddingTop: 0,
		paddingBottom: 0,
		'& .MuiListItemText-multiline': {
			margin: 8,
		},
	},
	desc: {
		display: '-webkit-box',
		'-webkit-line-clamp': 2,
		'-webkit-box-orient': 'vertical',
		overflow: 'hidden',
		width: '98%',
	},
	checkbox: {
		padding: '25px 6px 20px 6px',
		borderRadius: '0',
		'&:hover': {
			backgroundColor: 'transparent',
		},
	},
	infoTitle: {
		marginBottom: theme.spacing(1),
	},
	selectAllRoot: {
		padding: theme.spacing(0.5, 1),
		[theme.breakpoints.up('md')]: {
			width: 'calc(100% + 12px) !important',
		},
		'& .MuiFormControlLabel-root': {
			'& .MuiTypography-body1': {
				color: theme.palette.grey[500],
				fontSize: 14,
			},
		},
	},
	allpagesLink: {
		fontSize: 14,
		display: 'block',
		marginTop: theme.spacing(2),
		cursor: 'pointer',
	},
	selectedContent: {
		display: 'block',
		marginTop: theme.spacing(2),
	},
	exportBtn: {
		display: 'flex',
		alignItems: 'start',
		justifyContent: 'flex-end',
		textAlign: 'right',
		padding: '4px 0px 4px 4px !important',
		// '& .MuiButton-containedSizeSmall': {
		// 	margin: theme.spacing(0.5),
		// },
	},
	saveBtn: {
		marginLeft: theme.spacing(2),
		textTransform: 'capitalize',
		fontWeight: 'normal',
		color: theme.palette.primary.main,
		'&:hover': {
			backgroundColor: 'transparent',
			color: theme.palette.primary.main,
		},
	},
	moreBtnHolder: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		paddingLeft: '1rem',
	},
	moreBtn: {
		minWidth: 'unset',
		// marginLeft: theme.spacing(2),
		textTransform: 'capitalize',
		fontWeight: 'normal',
		color: theme.palette.grey[600],
		'&:hover': {
			backgroundColor: 'transparent',
			color: theme.palette.primary.main,
		},
	},
	sortBtn: {
		// display: 'block',
		// marginLeft: 'auto',
		width: '100%',
		textTransform: 'capitalize',
		fontWeight: 'normal',
		color: theme.palette.grey[600],
		padding: '4px 10px',
		'&:hover': {
			backgroundColor: 'transparent',
			color: theme.palette.primary.main,
		},
		'& .MuiIcon-fontSizeSmall': {
			fontSize: '1.25rem',
		},
		[theme.breakpoints.down('sm')]: {
			padding: '4px 5px',
		},
		[theme.breakpoints.only('sm')]: {
			textAlign: 'right',
		},
	},
	sortedValBtn: {
		'& .MuiButton-label': {
			width: '95%',
			overflow: 'hidden',
			whiteSpace: 'nowrap',
			display: 'block',
			textOverflow: 'ellipsis',

			[theme.breakpoints.down('sm')]: {
				lineHeight: 'unset',
			},
			[theme.breakpoints.down(330)]: {
				width: '65%',
			},
		},
	},
	orderByHolder: {
		flexWrap: 'nowrap',
	},
	whitelistBtn: {
		marginLeft: theme.spacing(2),
		textTransform: 'capitalize',
		color: theme.palette.error.main,
		'&:hover': {
			backgroundColor: 'transparent',
			color: theme.palette.error.main,
		},
	},
	infoSection: {
		display: 'flex',
		// justifyContent: 'space-between',
		paddingRight: theme.spacing(2),
		alignItems: 'center',
	},
	resultImg: {
		maxWidth: 150,
		width: '100%',
		marginTop: theme.spacing(1),
	},
	square: {
		width: '125px',
		height: '100px',
		// marginTop: '6px',
		marginRight: '5px',
		[theme.breakpoints.down('sm')]: {
			width: '75px',
			height: '50px',
		},
	},
	playIcon: {
		position: 'absolute',
		bottom: 10,
		right: 10,
		color: '#fff',
	},
	menuSectionHolder: {
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'start',
	},
	menuSection: {
		display: 'flex',
		justifyContent: 'space-between',
		width: '100%',
		'& .MuiFormGroup-root': {
			flexWrap: 'nowrap',
		},
	},
	upDownBtn: {
		[theme.breakpoints.down('xs')]: {
			padding: '5px',
		},
	},
	sortPopover: {
		'& .MuiPaper-root': {
			minWidth: '5rem',
			'& .MuiListItem-root': {
				justifyContent: 'center',
			},
		},
	},
	btnContainer: {
		display: 'flex',
		alignItems: 'center',
		flexDirection: 'row',
		'& .MuiIconButton-root': {
			padding: '6px',
			'&:hover': {
				color: theme.palette.primary.main,
			},
		},
	},
}))

ResultsList.propTypes = {
	isMobileView: PropTypes.bool.isRequired,
	// data: PropTypes.arrayOf(
	// 	PropTypes.shape({
	// 		data_source: PropTypes.string,
	// 		date: PropTypes.string,
	// 		description: PropTypes.string,
	// 		doc_type: PropTypes.string,
	// 		id: PropTypes.string,
	// 		title: PropTypes.string,
	// 		url: PropTypes.string,
	// 	})
	// ),
	data: PropTypes.array,
	onClickResult: PropTypes.func,
	onClickCheckbox: PropTypes.func,
	selectedList: PropTypes.array,
}

const savedResultsSchema = Yup.object().shape({
	category: Yup.string().trim().required('Please Select any Category'),
	resultName: Yup.string().trim().required('Result name is required'),
})

// const capitalizeFirstLetter = (string) => {
// 	if (string?.charAt(0) === '_') {
// 		let removeChar = string?.slice(1)
// 		return removeChar?.charAt(0).toUpperCase() + removeChar.slice(1)
// 	}
// 	return string?.charAt(0).toUpperCase() + string?.slice(1)
// }

function ResultsList({
	isMobileView,
	data,
	totalData,
	onClickResult,
	allDataSelected,
	onClickCheckbox,
	selectedList,
	checkAllSelected,
	onAllDataClick,
	isAllChecked,
	onClickExport,
	onClickWishList,
	onClickSavedSearch,
	onClickAnalyse,
	query,
	updateFunc,
	filterData,
	activeFilters,
	sorting_details,
	sortable_fields,
	filters,
	isFiltersOpen,
	toggle,
	setToggle,
}) {
	const classes = useStyles()
	const [selectedIds, setSelectedIds] = useState([])
	const [selectedDsIds, setSelectedDsIds] = useState([])
	const [showSavedPopup, setShowSavedPopup] = useState(false)
	const reduxDispatch = useDispatch()
	const [anchorEl, setAnchorEl] = React.useState(null)
	const [sortAnchorEl, setSortAnchorEl] = React.useState(null)
	const accessToken = useSelector((state) => _.get(state, 'session.authToken', ''))
	const activityState = sorting_details?.field
	const activityIcon = sorting_details?.sort_by === 'asc' ? 'arrow_downward' : 'arrow_upward'
	// const [toggle, setToggle] = useState(false)

	const dataConPerms = usePermission(ROLE_MODULES.DISCOVERX)
	const isExportAllowed = useMemo(() => _.get(dataConPerms, PERMISSION_TYPE.EXPORT, false), [dataConPerms])


	const filterLength = activeFilters?.common?.data_source?.length

	useEffect(() => {
		let tempIds = []
		let tempDsIds = []
		_.map(selectedList, (item) => {
			tempIds.push(item.id)
			tempDsIds.push(item.ds_id)
		})
		setSelectedIds(tempIds)
		setSelectedDsIds(tempDsIds)
	}, [selectedList])

	const toggleSavedResults = () => {
		setShowSavedPopup(!showSavedPopup)
	}
	const toggleMoreBtn = (event) => {
		setAnchorEl(event.currentTarget)
	}

	const toggleSortBtn = (event) => {
		setSortAnchorEl(event.currentTarget)
	}

	const onSubmitSavedResults = (e, filter) => {
		reduxDispatch(showLoader('Loading please wait...'))

		let tempFilterDataForAPI = {}
		_.map(filterData || {}, (o, d) => {
			if (!_.isEmpty(_.get(activeFilters, o?.path))) {
				if (o?.type === 'date') {
					let temp = {}
					if (!!_.get(activeFilters, o?.path)?.startDate && !!_.get(activeFilters, o?.path)?.endDate) {
						temp.from = convertLocalTimeToGmtStr(_.get(activeFilters, o?.path)?.startDate)
						temp.to = convertLocalTimeToGmtStr(_.get(activeFilters, o?.path)?.endDate)
						_.set(tempFilterDataForAPI, o?.path, {
							value: temp,
							...(o?.additionalDataForFilterApply || {}),
						})
					}
					return true
				}
				_.set(tempFilterDataForAPI, o?.path, {
					value: _.get(activeFilters, o?.path),
					...(o?.additionalDataForFilterApply || {}),
				})
			}
		})

		let data = {
			name: e?.resultName,
			query: query,
			filter_data: [tempFilterDataForAPI],
			category_id: e?.category,
		}

		const onSuccess = (res) => {
			reduxDispatch(
				showSuccessMessage(getSuccessMsg(res), 'Close', (e) => {
					reduxDispatch(hideLoader())
					toggleSavedResults()
					formik?.resetForm()
				})
			)
		}
		const onFailure = (err) => {
			reduxDispatch(showErrorMessage(getErrMsg(err), 'Close', () => reduxDispatch(hideLoader())))
		}
		DiscoverxApi.createSavedSearch(data).then(onSuccess, onFailure)
	}

	const actionMenus = [
		{
			label: 'My Wishlist',
			icon: 'favorite',
			actionName: PERMISSION_TYPE.WISHLIST,
		},
		{
			label: 'My Saved Searches',
			icon: 'bookmark',
			actionName: PERMISSION_TYPE.SAVED_SEARCH,
		},
	]

	const sortable = sortable_fields.map((item) => {
		const container = {}

		container.label = item
		// container.icon = sorting_details?.field === item ? (sorting_details?.sort_by === 'asc' ? 'arrow_downward' : 'arrow_upward') : 'arrow_downward'
		container.actionName = 'SORT'

		return container
	})

	sortable?.unshift({ label: 'None', actionName: 'SORT' })

	const toggleChange = (event) => {
		setToggle(!toggle)
	}

	// console.log(capitalizeFirstLetter('_gas'))

	const mobileActionMenus = [
		(!_.isEmpty(selectedList) || allDataSelected) && isExportAllowed
			? {
					label: 'Export Selected',
					icon: 'file_upload',
					actionName: PERMISSION_TYPE.EXPORT,
			  }
			: null,

		{
			label: 'Save result',
			icon: 'bookmark',
			actionName: PERMISSION_TYPE.SAVE,
		},
		{
			label: 'My Wishlist',
			icon: 'favorite',
			actionName: PERMISSION_TYPE.WISHLIST,
		},
		{
			label: 'My Saved Searches',
			icon: 'bookmark',
			actionName: PERMISSION_TYPE.SAVED_SEARCH,
		},
		// !_.isEmpty(selectedList) || allDataSelected
		// 	? {
		// 			label: 'Analyse',
		// 			icon: 'analytics',
		// 			actionName: PERMISSION_TYPE.ANALYSE,
		// 	  }
		// 	: null,
	]

	const fomatedActionItems = isMobileView ? _.compact(mobileActionMenus) : actionMenus

	const handleCloseMenu = () => {
		setAnchorEl(null)
		setSortAnchorEl(null)
	}

	const sortBy = (field, type) => {
		if (field === 'None') {
			updateFunc(undefined, { field: '', sort_by: '', limit: 0 })
		} else {
			updateFunc(undefined, { field, sort_by: type === 'arrow_downward' ? 'desc' : 'asc', limit: 0 })
		}
	}

	var singleDsSort = []
	_.forEach(filters, function (obj) {
		if (obj.category === 'common') singleDsSort.push(obj)
	})
	// console.log(_.isEmpty(sorting_details), 'active')

	const performAction = (actionName) => {
		switch (actionName) {
			case PERMISSION_TYPE.EXPORT:
				onClickExport()
				break
			case PERMISSION_TYPE.SAVE:
				toggleSavedResults()
				break
			case PERMISSION_TYPE.WISHLIST:
				onClickWishList()
				break
			case PERMISSION_TYPE.SAVED_SEARCH:
				onClickSavedSearch()
				break
			case 'SORT':
				sortBy()
				break
			case PERMISSION_TYPE.ANALYSE:
				onClickAnalyse()
				break
			default:
				break
		}
		handleCloseMenu()
	}

	const formik = useFormik({
		initialValues: {
			category: '',
			resultName: '',
		},
		onSubmit: (e) => {
			onSubmitSavedResults(e)
		},
		validationSchema: savedResultsSchema,
	})

	const getImageUrl = useCallback(
		(result) => {
			const defaultImageFilePath = _.get(result, 'default_image_file_path')
			const imgUrl = _.get(result, 'img_url')
			return _.isEmpty(imgUrl)
				? _.isEmpty(defaultImageFilePath)
					? null
					: getImgUrl(accessToken, defaultImageFilePath)
				: 
				// API_ENDPOINTS.CORS_API + 
				(_.isArray(imgUrl) ? imgUrl[0] : imgUrl)
		},
		[accessToken]
	)

	return (
		<div className={classes.root}>
			<Grid container className={classes.selectAllRoot} justifyContent='space-between' spacing={1}>
				<Grid item xs={5} md={9} lg={9} className={classes.menuSectionHolder}>
					<div className={classes.menuSection}>
						<FormGroup row>
							<FormControlLabel
								control={
									<Checkbox
										checked={isAllChecked || allDataSelected}
										size='small'
										disableTouchRipple
										onChange={(event) => checkAllSelected(event, data)}
									/>
								}
								label='Select All'
							/>
							{!isMobileView && (
								<Button size='small' className={classes.saveBtn} onClick={toggleSavedResults}>
									<Icon fontSize='small'>bookmark</Icon> Save result
								</Button>
							)}
						</FormGroup>
						{!isMobileView && (singleDsSort[0]?.defaultOptions?.length <= 1 || (filterLength !== undefined && filterLength === 1)) && (
							<div className={classes.btnContainer} style={{ maxWidth: isFiltersOpen ? '140px' : 'unset' }}>
								<Button
									size='small'
									className={classNames(classes.sortBtn, !_.isEmpty(sorting_details) && classes.sortedValBtn)}
									onClick={toggleSortBtn}
									startIcon={_.isEmpty(sorting_details) && <Icon fontSize='small'>sort</Icon>}
								>
									{_.isEmpty(sorting_details) ? 'Sort by' : _.startCase(sorting_details.field)}
								</Button>
								{!_.isEmpty(sorting_details) && (
									<IconButton onClick={() => sortBy(activityState, activityIcon)}>
										<Icon onClick={() => sortBy(activityState, activityIcon)} fontSize='small'>
											{activityIcon}
										</Icon>
									</IconButton>
								)}
							</div>
						)}

						{!isMobileView && (
							<div className={classes.btnContainer}>
								<Button size='small' className={classes.moreBtn} onClick={toggleMoreBtn}>
									<Icon fontSize='small'>more_vert</Icon> More
								</Button>
							</div>
						)}
						{/* {!isMobileView && ( */}
						<div>
							<FormControlLabel
								value='start'
								control={<Switch color='primary' checked={toggle} onChange={toggleChange} />}
								label={toggle === true ? 'List View' : 'Grid View'}
								labelPlacement='start'
							/>
						</div>
						{/* )} */}
					</div>
					<PopoverMenus
						open={Boolean(sortAnchorEl)}
						id={'1'}
						menus={sortable}
						anchorEl={sortAnchorEl}
						onClickAction={sortBy}
						onClose={handleCloseMenu}
						sortBy={sortBy}
						activityState={activityState}
						activityIcon={activityIcon}
						className={classes.sortPopover}
					/>
					<PopoverMenus
						open={Boolean(anchorEl)}
						id={'1'}
						menus={fomatedActionItems}
						anchorEl={anchorEl}
						onClickAction={performAction}
						onClose={handleCloseMenu}
					/>
					<Hidden smDown>
						<Divider />
					</Hidden>
					{(isAllChecked || allDataSelected) && (
						<>
							{allDataSelected ? (
								<Typography color='primary' variant='caption' className={classes.selectedContent}>
									{totalData} data Selected!
								</Typography>
							) : (
								<Link onClick={onAllDataClick} className={classes.allpagesLink}>
									Select results of all pages
								</Link>
							)}
						</>
					)}
				</Grid>
				<Grid item xs={7} md={3} lg={3} className={classes.exportBtn}>
					{!isMobileView ? (
						<>
							{isExportAllowed &&
								<Button
								variant='contained'
								size='small'
								onClick={onClickExport}
								disabled={_.isEmpty(selectedList) && !allDataSelected}
								color='primary'
								disableElevation
							>
								Export Selected
							</Button>}
							{/* <Button
								variant='contained'
								size='small'
								onClick={onClickAnalyse}
								disabled={_.isEmpty(selectedList) && !allDataSelected}
								color='primary'
								disableElevation
							>
								Analyse
							</Button> */}
						</>
					) : (
						<Grid item container xs={12} direction='row' alignItems='center'>
							<Grid item container xs={10} direction='row' alignItems='center' className={classes.orderByHolder}>
								{(singleDsSort[0]?.defaultOptions.length <= 1 || (filterLength !== undefined && filterLength === 1)) && (
									<Grid item xs={10}>
										<Button
											size='small'
											className={classNames(classes.sortBtn, !_.isEmpty(sorting_details) && classes.sortedValBtn)}
											onClick={toggleSortBtn}
											startIcon={_.isEmpty(sorting_details) && <Icon fontSize='small'>sort</Icon>}
										>
											{_.isEmpty(sorting_details) ? 'Sort by' : _.startCase(sorting_details.field)}
										</Button>
									</Grid>
								)}
								{!_.isEmpty(sorting_details) && (
									<Grid item xs={2}>
										<IconButton onClick={() => sortBy(activityState, activityIcon)} className={classes.upDownBtn}>
											<Icon onClick={() => sortBy(activityState, activityIcon)} fontSize='small'>
												{activityIcon}
											</Icon>
										</IconButton>
									</Grid>
								)}
							</Grid>
							<Grid item xs={2} className={classes.moreBtnHolder}>
								<Button size='small' className={classes.moreBtn} onClick={toggleMoreBtn}>
									<Icon fontSize='small'>more_vert</Icon>
								</Button>
								<PopoverMenus
									open={Boolean(anchorEl)}
									id={'1'}
									menus={fomatedActionItems}
									anchorEl={anchorEl}
									onClickAction={performAction}
									onClose={handleCloseMenu}
								/>
							</Grid>
						</Grid>
					)}
					{/* analytics */}
				</Grid>
			</Grid>
			<Hidden mdUp>
				<Divider />
			</Hidden>
			{toggle === true ? (
				// <AgGridCustom
				// 	suppressRowClickSelection={true}
				// 	onGridReady={onGridReady}
				// 	rowSelection={'multiple'}
				// 	onChangeSelection={onChangeSelection}
				// 	columnDefs={gridHeaders}
				// 	rowData={data}
				// 	onRowSelected={onRowSelected}
				// 	// loading={isFetching}

				// 	disableClickSelectionRenderers={['actionCellRenderer']}
				// 	onRowClicked={onRowClicked}
				// 	// rowHeight={_.isEqual(width, 'xs') ? 60 : 50}
				// 	// noDataTxt={!_.isEqual(currentUser, 'All') ? `No ${currentUser} users found` : 'No users found'}
				// />

				// <TableBody>
				// 	<List className={classes.root}>
				// 		{_.map(data, (result, idx) => {
				// 			const isChecked = _.indexOf(selectedIds, result?.id) !== -1 && _.indexOf(selectedDsIds, result?.ds_id) !== -1
				// 			return (
				// 				<TableRow>
				// 					<ListItem
				// 						onClick={(e) => {
				// 							onClickResult && onClickResult(e, result)
				// 						}}
				// 						classes={{ root: classes.listItemRoot }}
				// 						key={_.get(result, 'id') + idx}
				// 						disableGutters
				// 						alignItems='flex-start'
				// 						button
				// 					>
				// 						<ListItemAvatar className={classes.cbSection}>
				// 							<Checkbox
				// 								checked={isChecked || allDataSelected}
				// 								onClick={(e) => {
				// 									onClickCheckbox(e, result)
				// 								}}
				// 								className={classes.checkbox}
				// 								size='small'
				// 								disableTouchRipple
				// 							/>
				// 						</ListItemAvatar>

				// 						<TableCell align='right'>{result.ds_name}</TableCell>
				// 						<TableCell align='right'>{result.title}</TableCell>
				// 						<TableCell align='right'>{result.description}</TableCell>
				// 					</ListItem>
				// 				</TableRow>
				// 			)
				// 		})}
				// 	</List>
				// </TableBody>

				<TableContainer component={Paper}>
					<Table size='small' aria-label='a dense table'>
						<TableHead style={{ backgroundColor: '#9e9c9c14' }}>
							<TableRow>
								<TableCell align='center'>SELECT</TableCell>
								<TableCell align='center'>DS NAME</TableCell>
								<TableCell align='center'>TITLE</TableCell>
								<TableCell align='center'>DESCRIPTION</TableCell>
							</TableRow>
						</TableHead>
						<TableBody>
							{data.map((row, idx) => {
								const isChecked = _.indexOf(selectedIds, row?.id) !== -1 && _.indexOf(selectedDsIds, row?.ds_id) !== -1
								return (
									// <ListItem
									// 	onClick={(e) => {
									// 		onClickResult && onClickResult(e, row)
									// 	}}
									// 	classes={{ root: classes.listItemRoot }}
									// 	key={_.get(row, 'id') + idx}
									// 	disableGutters
									// 	alignItems='flex-start'
									// 	button
									// >
									<TableRow
										key={row.name}
										onClick={(e) => {
											onClickResult && onClickResult(e, row)
										}}
									>
										<TableCell align='center'>
											<Checkbox
												checked={isChecked || allDataSelected}
												onClick={(e) => {
													onClickCheckbox(e, row)
												}}
												className={classes.checkbox}
												size='small'
												disableTouchRipple
											/>
										</TableCell>
										<TableCell align='center' style={{ wordWrap: 'break-word', maxWidth: 250 }}>
											{row.ds_name}
										</TableCell>
										<TableCell align='center' style={{ wordWrap: 'break-word', maxWidth: 250 }}>
											{row.title}
										</TableCell>
										<TableCell align='center' style={{ wordWrap: 'break-word', maxWidth: 250 }}>
											{row.description}
										</TableCell>
									</TableRow>
									// </ListItem>
								)
							})}
						</TableBody>
					</Table>
				</TableContainer>
			) : (
				// <Table data={data}></Table>
				<List className={classes.root}>
					{_.map(data, (result, idx) => {
						const isChecked = _.indexOf(selectedIds, result?.id) !== -1 && _.indexOf(selectedDsIds, result?.ds_id) !== -1
						return (
							<ListItem
								onClick={(e) => {
									onClickResult && onClickResult(e, result)
								}}
								classes={{ root: classes.listItemRoot }}
								key={_.get(result, 'id') + idx}
								disableGutters
								alignItems='flex-start'
								button
							>
								<ListItemAvatar className={classes.cbSection}>
									<Checkbox
										checked={isChecked || allDataSelected}
										onClick={(e) => {
											onClickCheckbox(e, result)
										}}
										className={classes.checkbox}
										size='small'
										disableTouchRipple
									/>
								</ListItemAvatar>
								<ListItemText
									primary={
										<React.Fragment>
											<Typography className={classes.infoTitle} variant='caption' color='textSecondary'>
												{_.get(result, 'data_source')} &nbsp;
												{!_.isEqual(_.get(result, 'doc_type'), null) && <span className={classes.docType}>{_.get(result, 'doc_type')}</span>}
											</Typography>
											<Typography variant={isMobileView ? 'body2' : 'body1'} fontWeight='bold' color='textPrimary' className={classes.title}>
												{_.get(result, 'title')}
											</Typography>
											{/* <Typography
											variant={isMobileView ? 'body2' : 'body1'}
											fontWeight='bold'
											color='textPrimary'
											dangerouslySetInnerHTML={{
												__html: _.get(result, 'title'),
											}}
											className={classes.title}
										/> */}
										</React.Fragment>
									}
									secondary={
										<React.Fragment>
											<Typography component='div' gutterBottom className={classes.desc} variant='body2' color='textSecondary'>
												{_.get(result, 'description')}
											</Typography>
											<div className={classes.infoSection}>
												{_.get(result, 'date') && (
													<Typography component='span' className={classes.timeInfo} variant='caption'>
														<Icon fontSize='inherit'>query_builder</Icon>
														<span>{moment(_.get(result, 'date')).format('DD MMMM YYYY')}</span>
													</Typography>
												)}
											</div>
										</React.Fragment>
									}
								/>
								{!isMobileView && !_.isEmpty(getImageUrl(result)) && (
									<ListItemAvatar className={classes.imgSection}>
										<Avatar variant='square' className={classes.square} src={getImageUrl(result)}>
											<Icon>image</Icon>
										</Avatar>
									</ListItemAvatar>
								)}
								{!isMobileView && !_.isEmpty(result?.video_thumbnail_url) && (
									<ListItemAvatar className={classes.imgSection}>
										<Avatar
											variant='square'
											className={classes.square}
											src={_.isArray(result?.video_thumbnail_url) ? result?.video_thumbnail_url[0] : result?.video_thumbnail_url}
										>
											{_.startCase(_.slice(result?.title, 0, 1))}
										</Avatar>
										<Icon className={classes.playIcon}>play_circle_outline</Icon>
									</ListItemAvatar>
								)}
							</ListItem>
						)
					})}
				</List>
			)}
			<SaveResultPopup formik={formik} open={showSavedPopup} handleClose={toggleSavedResults} isMobileView={isMobileView} />
		</div>
	)
}

export default ResultsList
